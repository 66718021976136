import { geminiModel } from '../config/firebase';
import type { DrivetrainType, Vehicle } from '../types/vehicle';
import type { Part } from '../types/part';

interface TrackTip {
  tip: string;
  confidence: number;
  category: string;
}

interface AITrackTipsResponse {
  tips: TrackTip[];
}

interface VehicleSpecs {
  power: number;
  weight: number;
  drivetrain: DrivetrainType;
}

export async function generateTrackTips(location: string): Promise<TrackTip[]> {
  try {
    const prompt = `
      As a motorsport expert, provide specific tips for racing at ${location}.
      Focus on track conditions, racing strategy, and weather considerations.
      
      Provide the response in the following JSON format:
      {
        "tips": [
          {
            "tip": "specific tip here",
            "category": "one of: surface, strategy, weather, general"
          }
        ]
      }
      
      Provide exactly 3 tips, each in a different category.
      Make the tips specific to the location and actionable for racers.
      Return ONLY the JSON with no markdown formatting.
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      // Clean up the response by removing markdown code blocks
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const parsedResponse: AITrackTipsResponse = JSON.parse(cleanJson);
      return parsedResponse.tips;
    } catch (error) {
      console.error('Failed to parse AI response:', error);
      console.error('Raw response:', text);
      return [];
    }
  } catch (error) {
    console.error('Error generating track tips:', error);
    return [];
  }
}

export async function getVehicleSpecs(
  year: number,
  make: string,
  model: string,
  trim?: string
): Promise<VehicleSpecs | null> {
  try {
    const prompt = `
      Return ONLY a JSON object with the following specifications for a ${year} ${make} ${model} ${trim || ''}:
      - Factory horsepower (power)
      - Factory curb weight in pounds (weight)
      - Drivetrain type as either "FWD", "RWD", "AWD", or "4WD" (drivetrain)
      
      Format:
      {
        "power": number,
        "weight": number,
        "drivetrain": "FWD" | "RWD" | "AWD" | "4WD"
      }
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const specs = JSON.parse(cleanJson) as VehicleSpecs;
      return specs;
    } catch (error) {
      console.error('Failed to parse AI response:', error);
      return null;
    }
  } catch (error) {
    console.error('Error getting vehicle specs:', error);
    return null;
  }
}

export async function getPartInfo(userInput: string, vehicle: Vehicle): Promise<Partial<Part>> {
  try {
    const vehicleContext = `This part is specifically for a ${vehicle.year} ${vehicle.make} ${vehicle.model}.`;

    const prompt = `
      Given this user input describing a car part: "${userInput}"
      Context: ${vehicleContext}
      Note: Use the vehicle context only for determining compatibility and specifications.
      
      Please identify the part and return a standardized, user-friendly name and categorization.
      The response must use these exact categories and subcategories:

      Brakes:
        - Pads, Rotors, Fluid, Lines, Calipers, Master Cylinder, Brake Ducts
      Tires:
        - Summer, Winter, All Season, Track, Rain
      Engine:
        - Oil, Filter, Spark Plugs, Belts, Timing Components, Intake, Turbo, Supercharger, Engine Management
      Suspension:
        - Coilovers, Springs, Shocks, Sway Bars, Control Arms, Bushings, Alignment Parts, Wheels
      Transmission:
        - Fluid, Clutch, Flywheel, Shifter, Differential, Gears
      Exterior:
        - Aero, Body Panels, Lighting, Paint, Wraps
      Interior:
        - Seats, Harnesses, Roll Cage, Gauges, Electronics
      Electrical:
        - Battery, Alternator, Starter, Wiring, Sensors
      Cooling:
        - Radiator, Oil Cooler, Intercooler, Fans, Hoses
      Fuel System:
        - Pump, Filter, Injectors, Lines, Tank
      Exhaust:
        - Headers, Catalytic Converter, Muffler, Full System
      Drivetrain:
        - Axles, Driveshaft, CV Joints, Transfer Case
      Other:
        - Miscellaneous
      
      Format the name to be descriptive but concise, including ONLY:
      - Model/Series name (if applicable)
      - Part type and any distinguishing features
      Example: "BR Series Coilovers" not "BC Racing BR Series Coilovers"
      Example: "High Performance Radiator" not "Fluidyne High Performance Radiator"
      
      The manufacturer should be returned separately in the manufacturer field.
      
      Use the vehicle context to suggest appropriate parts and verify fitment,
      but keep the part name generic and reusable.
      
      Return ONLY a JSON object with these exact properties:
      {
        "name": "formatted name (no manufacturer or vehicle info)",
        "manufacturer": "brand name only",
        "category": "exact category from list above",
        "subcategory": "exact subcategory from list above",
        "cost": estimated cost as number,
        "description": "brief description including fitment details"
      }
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const partInfo = JSON.parse(cleanJson) as Partial<Part>;
      return partInfo;
    } catch (error) {
      console.error('Failed to parse AI response:', error);
      console.error('Raw response:', text);
      throw new Error('Failed to parse part information');
    }
  } catch (error) {
    console.error('Error getting part info:', error);
    throw error;
  }
} 