import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { BudgetItem, ExpenseCategory, IncomeCategory, FrequencyType } from '../../types/budget';

interface BudgetItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (item: BudgetItem) => Promise<void>;
  type: 'expense' | 'income';
}

export function BudgetItemModal({ isOpen, onClose, onAdd, type }: BudgetItemModalProps) {
  const [item, setItem] = useState<Partial<BudgetItem>>({
    description: '',
    amount: 0,
    quantity: 1,
    category: type === 'expense' ? 'Entry Fees' : 'Sponsorship',
    frequency: 'one-time'
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!item.description || !item.amount) return;

    const newItem: BudgetItem = {
      id: crypto.randomUUID(),
      description: item.description,
      amount: Number(item.amount),
      quantity: Number(item.quantity) || 1,
      category: item.category as (ExpenseCategory | IncomeCategory),
      frequency: item.frequency as FrequencyType,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    await onAdd(newItem);
    onClose();
    setItem({
      description: '',
      amount: 0,
      quantity: 1,
      category: type === 'expense' ? 'Entry Fees' : 'Sponsorship',
      frequency: 'one-time'
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-dark-300 rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-white">
            Add {type === 'expense' ? 'Expense' : 'Income'}
          </h3>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm text-gray-400 mb-1">Category</label>
            <select
              value={item.category}
              onChange={(e) => setItem(prev => ({ ...prev, category: e.target.value }))}
              className="w-full bg-dark-200 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            >
              {type === 'expense' ? (
                <>
                  <option value="Entry Fees">Entry Fees</option>
                  <option value="Travel">Travel</option>
                  <option value="Fuel">Fuel</option>
                  <option value="Tires">Tires</option>
                  <option value="Parts">Parts</option>
                  <option value="Maintenance">Maintenance</option>
                  <option value="Equipment">Equipment</option>
                  <option value="Safety Gear">Safety Gear</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Other">Other</option>
                </>
              ) : (
                <>
                  <option value="Sponsorship">Sponsorship</option>
                  <option value="Prize Money">Prize Money</option>
                  <option value="Merchandise">Merchandise</option>
                  <option value="Appearance Fees">Appearance Fees</option>
                  <option value="Other">Other</option>
                </>
              )}
            </select>
          </div>

          <div>
            <label className="block text-sm text-gray-400 mb-1">Description</label>
            <input
              type="text"
              value={item.description}
              onChange={(e) => setItem(prev => ({ ...prev, description: e.target.value }))}
              className="w-full bg-dark-200 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm text-gray-400 mb-1">Amount</label>
            <input
              type="number"
              value={item.amount || ''}
              onChange={(e) => setItem(prev => ({ ...prev, amount: Number(e.target.value) }))}
              className="w-full bg-dark-200 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm text-gray-400 mb-1">Frequency</label>
            <select
              value={item.frequency}
              onChange={(e) => setItem(prev => ({ ...prev, frequency: e.target.value as FrequencyType }))}
              className="w-full bg-dark-200 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            >
              <option value="one-time">One Time</option>
              <option value="per-event">Per Event</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="yearly">Yearly</option>
              <option value="per-season">Per Season</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-primary-500 text-white rounded-md px-4 py-2 hover:bg-primary-600"
          >
            Add {type === 'expense' ? 'Expense' : 'Income'}
          </button>
        </form>
      </div>
    </div>
  );
} 