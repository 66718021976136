import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Bell, Moon, Sun } from 'lucide-react';

export default function Settings() {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState({
    email: true,
    push: true,
    maintenance: true,
    events: true,
    budget: false
  });
  const [theme, setTheme] = useState('dark');
  const [message, setMessage] = useState('');

  const handleNotificationChange = (key: keyof typeof notifications) => {
    setNotifications(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleSaveSettings = async () => {
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      setMessage('Settings saved successfully');
    } catch (error) {
      setMessage('Failed to save settings');
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Settings</h1>

      {/* Notifications */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <Bell className="w-5 h-5 text-primary-500" />
          <h2 className="text-lg font-semibold text-white">
            Notification Preferences
          </h2>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-white">Email Notifications</p>
              <p className="text-sm text-gray-400">
                Receive updates via email
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={notifications.email}
                onChange={() => handleNotificationChange('email')}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-dark-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-500/20 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500"></div>
            </label>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <p className="text-white">Push Notifications</p>
              <p className="text-sm text-gray-400">
                Receive browser notifications
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={notifications.push}
                onChange={() => handleNotificationChange('push')}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-dark-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-500/20 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500"></div>
            </label>
          </div>

          <div className="pt-4 border-t border-dark-50">
            <p className="text-sm font-medium text-gray-400 mb-4">
              Notify me about:
            </p>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <p className="text-white">Maintenance Reminders</p>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={notifications.maintenance}
                    onChange={() => handleNotificationChange('maintenance')}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-dark-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-500/20 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-white">Upcoming Events</p>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={notifications.events}
                    onChange={() => handleNotificationChange('events')}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-dark-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-500/20 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500"></div>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <p className="text-white">Budget Alerts</p>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={notifications.budget}
                    onChange={() => handleNotificationChange('budget')}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-dark-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-500/20 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Theme Settings */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          {theme === 'dark' ? (
            <Moon className="w-5 h-5 text-primary-500" />
          ) : (
            <Sun className="w-5 h-5 text-primary-500" />
          )}
          <h2 className="text-lg font-semibold text-white">
            Theme Settings
          </h2>
        </div>

        <div className="flex items-center gap-4">
          <button
            onClick={() => setTheme('light')}
            className={`flex-1 p-4 rounded-lg border-2 ${
              theme === 'light'
                ? 'border-primary-500 bg-primary-500/10'
                : 'border-dark-50 hover:border-primary-500/50'
            }`}
          >
            <Sun className="w-6 h-6 mx-auto mb-2 text-white" />
            <p className="text-white">Light</p>
          </button>
          <button
            onClick={() => setTheme('dark')}
            className={`flex-1 p-4 rounded-lg border-2 ${
              theme === 'dark'
                ? 'border-primary-500 bg-primary-500/10'
                : 'border-dark-50 hover:border-primary-500/50'
            }`}
          >
            <Moon className="w-6 h-6 mx-auto mb-2 text-white" />
            <p className="text-white">Dark</p>
          </button>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSaveSettings}
          className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
        >
          Save Settings
        </button>
      </div>

      {message && (
        <div
          className={`p-4 rounded-md ${
            message.includes('success')
              ? 'bg-green-500/10 text-green-500'
              : 'bg-red-500/10 text-red-500'
          }`}
        >
          {message}
        </div>
      )}
    </div>
  );
}