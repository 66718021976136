import React, { useState, useEffect } from 'react';
import { ChevronDown, Loader2, Save } from 'lucide-react';
import type { VehicleSetup } from '../../../types/vehicle';

interface VehicleSetupProps {
  setup?: VehicleSetup;
  onUpdateSetup: (setup: VehicleSetup) => void;
}

interface SetupSectionProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

function SetupSection({ title, isOpen, onToggle, children }: SetupSectionProps) {
  return (
    <div className="border border-dark-50 rounded-lg overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between p-4 bg-dark-100 hover:bg-dark-50 transition-colors"
      >
        <h3 className="text-lg font-medium text-white">{title}</h3>
        <ChevronDown className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      <div className={`transition-all duration-200 ${isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="p-4 space-y-4">
          {children}
        </div>
      </div>
    </div>
  );
}

const createInitialSetup = () => ({
  id: 'default',
  setupName: 'Default Setup',
  setupDescription: null,
  alignment: {
    frontCamber: 0,
    rearCamber: 0,
    frontToe: 0,
    rearToe: 0,
    caster: 0
  },
  suspension: {
    frontRideHeight: 0,
    rearRideHeight: 0,
    frontSpringRate: 0,
    rearSpringRate: 0,
    frontRebound: 0,
    rearRebound: 0,
    frontCompression: 0,
    rearCompression: 0,
    frontBarSetting: null,
    rearBarSetting: null
  },
  aero: {
    frontDownforce: 0,
    rearDownforce: 0,
    wingAngle: 0,
    splitterHeight: 0,
    rideHeight: 0
  },
  tires: {
    compound: 'Unknown',
    frontPressureCold: 0,
    rearPressureCold: 0,
    frontPressureHot: 0,
    rearPressureHot: 0
  },
  brakes: {
    brakeBias: 50,
    frontPressure: 0,
    rearPressure: 0,
    padType: 'Unknown'
  },
  differential: {
    frontPreload: null,
    rearPreload: null,
    centerPreload: null,
    frontRampAngle: null,
    rearRampAngle: null,
    lockingPercentage: null
  },
  gearing: {
    finalDrive: 0,
    gearRatios: []
  },
  weight: {
    cornerWeights: {
      frontLeft: 0,
      frontRight: 0,
      rearLeft: 0,
      rearRight: 0
    },
    ballast: 0,
    ballastPosition: 'N/A',
    totalWeight: 0,
    distribution: {
      frontToRear: 50,
      leftToRight: 50
    }
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
});

export function getSetupSummary(setup?: VehicleSetup) {
  if (!setup) return null;
  
  return {
    alignment: `${setup.alignment.frontCamber}° / ${setup.alignment.rearCamber}° camber`,
    suspension: `${setup.suspension.frontRideHeight}mm / ${setup.suspension.rearRideHeight}mm height`,
    tires: {
      compound: setup.tires.compound,
      pressures: `${setup.tires.frontPressureCold}/${setup.tires.rearPressureCold} psi cold`
    }
  };
}

export default function VehicleSetupComponent({ setup, onUpdateSetup }: VehicleSetupProps) {
  const [currentSetup, setCurrentSetup] = useState<VehicleSetup>({
    id: setup?.id || 'default',
    setupName: setup?.setupName || 'Default Setup',
    setupDescription: setup?.setupDescription || '',
    alignment: {
      frontCamber: setup?.alignment?.frontCamber || 0,
      rearCamber: setup?.alignment?.rearCamber || 0,
      frontToe: setup?.alignment?.frontToe || 0,
      rearToe: setup?.alignment?.rearToe || 0,
      caster: setup?.alignment?.caster || 0
    },
    suspension: {
      frontRideHeight: setup?.suspension?.frontRideHeight || 0,
      rearRideHeight: setup?.suspension?.rearRideHeight || 0,
      frontSpringRate: setup?.suspension?.frontSpringRate || 0,
      rearSpringRate: setup?.suspension?.rearSpringRate || 0,
      frontRebound: setup?.suspension?.frontRebound || 0,
      rearRebound: setup?.suspension?.rearRebound || 0,
      frontCompression: setup?.suspension?.frontCompression || 0,
      rearCompression: setup?.suspension?.rearCompression || 0,
      frontBarSetting: setup?.suspension?.frontBarSetting || 0,
      rearBarSetting: setup?.suspension?.rearBarSetting || 0
    },
    aero: {
      frontDownforce: setup?.aero?.frontDownforce || 0,
      rearDownforce: setup?.aero?.rearDownforce || 0,
      wingAngle: setup?.aero?.wingAngle || 0,
      splitterHeight: setup?.aero?.splitterHeight || 0,
      rideHeight: setup?.aero?.rideHeight || 0
    },
    tires: {
      compound: setup?.tires?.compound || 'Unknown',
      frontPressureCold: setup?.tires?.frontPressureCold || 0,
      rearPressureCold: setup?.tires?.rearPressureCold || 0,
      frontPressureHot: setup?.tires?.frontPressureHot || 0,
      rearPressureHot: setup?.tires?.rearPressureHot || 0
    },
    brakes: {
      brakeBias: setup?.brakes?.brakeBias || 50,
      frontPressure: setup?.brakes?.frontPressure || 0,
      rearPressure: setup?.brakes?.rearPressure || 0,
      padType: setup?.brakes?.padType || 'Unknown'
    },
    differential: {
      frontPreload: setup?.differential?.frontPreload || undefined,
      rearPreload: setup?.differential?.rearPreload || undefined,
      centerPreload: setup?.differential?.centerPreload || undefined,
      frontRampAngle: setup?.differential?.frontRampAngle || undefined,
      rearRampAngle: setup?.differential?.rearRampAngle || undefined,
      lockingPercentage: setup?.differential?.lockingPercentage || undefined
    },
    gearing: {
      finalDrive: setup?.gearing?.finalDrive || 0,
      gearRatios: setup?.gearing?.gearRatios || []
    },
    weight: {
      cornerWeights: {
        frontLeft: setup?.weight?.cornerWeights?.frontLeft || 0,
        frontRight: setup?.weight?.cornerWeights?.frontRight || 0,
        rearLeft: setup?.weight?.cornerWeights?.rearLeft || 0,
        rearRight: setup?.weight?.cornerWeights?.rearRight || 0
      },
      ballast: setup?.weight?.ballast || 0,
      ballastPosition: setup?.weight?.ballastPosition || 'N/A',
      totalWeight: setup?.weight?.totalWeight || 0,
      distribution: {
        frontToRear: setup?.weight?.distribution?.frontToRear || 50,
        leftToRight: setup?.weight?.distribution?.leftToRight || 50
      }
    },
    createdAt: setup?.createdAt || new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [openSections, setOpenSections] = useState({
    alignment: true,
    suspension: false,
    brakes: false,
    tires: false,
    aero: false,
    differential: false,
    gearing: false,
    weight: false
  });

  // Update currentSetup when setup prop changes
  useEffect(() => {
    if (setup) {
      setCurrentSetup(setup);
    }
  }, [setup]);

  const toggleSection = (section: keyof typeof openSections) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleChange = (
    section: keyof VehicleSetup,
    field: string,
    value: number | string | number[]
  ) => {
    setCurrentSetup(prev => {
      if (section === 'setupName') {
        const setupName = String(value).trim() || 'Default Setup';
        return {
          ...prev,
          setupName
        };
      }

      // Handle nested fields with dots (for tire pressures)
      if (field.includes('.')) {
        const [parentField, childField] = field.split('.');
        const sectionObj = prev[section] as Record<string, any>;
        
        return {
          ...prev,
          [section]: {
            ...sectionObj,
            [parentField]: {
              ...(sectionObj[parentField] as Record<string, any>),
              [childField]: typeof value === 'string' ? value.trim() || 'N/A' : Number(value)
            }
          }
        };
      }

      // Handle arrays (for gear ratios)
      if (Array.isArray(value)) {
        const sectionObj = prev[section] as Record<string, any>;
        return {
          ...prev,
          [section]: {
            ...sectionObj,
            [field]: value
          }
        };
      }

      // Handle other fields
      if (field) {
        const sectionObj = prev[section] as Record<string, any>;
        return {
          ...prev,
          [section]: {
            ...sectionObj,
            [field]: typeof value === 'string' ? value.trim() || 'N/A' : Number(value)
          }
        };
      }

      return prev;
    });
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError('');
      await onUpdateSetup(currentSetup);
    } catch (err) {
      console.error('Error saving setup:', err);
      setError('Failed to save setup');
    } finally {
      setIsSaving(false);
    }
  };

  // Update the input value handling to convert null to empty string
  const getInputValue = (value: string | number | null | undefined): string => {
    if (value === null || value === undefined) return '';
    return value.toString();
  };

  return (
    <div className="space-y-8">
      {/* Setup Name */}
      <div>
        <input
          type="text"
          value={currentSetup.setupName}
          onChange={(e) => handleChange('setupName', '', e.target.value)}
          className="text-xl font-semibold bg-transparent border-none p-0 text-white focus:ring-0 w-full"
          placeholder="Setup Name"
        />
      </div>

      {/* Alignment Section */}
      <SetupSection
        title="Alignment"
        isOpen={openSections.alignment}
        onToggle={() => toggleSection('alignment')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Camber (°)
            </label>
            <input
              type="number"
              step="0.1"
              value={getInputValue(currentSetup.alignment.frontCamber)}
              onChange={(e) => handleChange('alignment', 'frontCamber', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Camber (°)
            </label>
            <input
              type="number"
              step="0.1"
              value={getInputValue(currentSetup.alignment.rearCamber)}
              onChange={(e) => handleChange('alignment', 'rearCamber', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Toe (°)
            </label>
            <input
              type="number"
              step="0.1"
              value={getInputValue(currentSetup.alignment.frontToe)}
              onChange={(e) => handleChange('alignment', 'frontToe', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Toe (°)
            </label>
            <input
              type="number"
              step="0.1"
              value={getInputValue(currentSetup.alignment.rearToe)}
              onChange={(e) => handleChange('alignment', 'rearToe', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Caster (°)
            </label>
            <input
              type="number"
              step="0.1"
              value={getInputValue(currentSetup.alignment.caster)}
              onChange={(e) => handleChange('alignment', 'caster', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
        </div>
      </SetupSection>

      {/* Suspension Section */}
      <SetupSection
        title="Suspension"
        isOpen={openSections.suspension}
        onToggle={() => toggleSection('suspension')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Ride Height (mm)
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.suspension.frontRideHeight)}
              onChange={(e) => handleChange('suspension', 'frontRideHeight', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Ride Height (mm)
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.suspension.rearRideHeight)}
              onChange={(e) => handleChange('suspension', 'rearRideHeight', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Spring Rate (lbs/in)
            </label>
            <input
              type="number"
              step="50"
              value={getInputValue(currentSetup.suspension.frontSpringRate)}
              onChange={(e) => handleChange('suspension', 'frontSpringRate', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Spring Rate (lbs/in)
            </label>
            <input
              type="number"
              step="50"
              value={getInputValue(currentSetup.suspension.rearSpringRate)}
              onChange={(e) => handleChange('suspension', 'rearSpringRate', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Rebound
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.suspension.frontRebound)}
              onChange={(e) => handleChange('suspension', 'frontRebound', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Rebound
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.suspension.rearRebound)}
              onChange={(e) => handleChange('suspension', 'rearRebound', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Compression
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.suspension.frontCompression)}
              onChange={(e) => handleChange('suspension', 'frontCompression', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Compression
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.suspension.rearCompression)}
              onChange={(e) => handleChange('suspension', 'rearCompression', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          {currentSetup.suspension.frontBarSetting !== undefined && (
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Sway Bar Setting
              </label>
              <input
                type="number"
                step="1"
                value={getInputValue(currentSetup.suspension.frontBarSetting)}
                onChange={(e) => handleChange('suspension', 'frontBarSetting', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          )}
          {currentSetup.suspension.rearBarSetting !== undefined && (
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Sway Bar Setting
              </label>
              <input
                type="number"
                step="1"
                value={getInputValue(currentSetup.suspension.rearBarSetting)}
                onChange={(e) => handleChange('suspension', 'rearBarSetting', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          )}
        </div>
      </SetupSection>

      {/* Aero Section */}
      <SetupSection
        title="Aerodynamics"
        isOpen={openSections.aero}
        onToggle={() => toggleSection('aero')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Downforce (lbs)
            </label>
            <input
              type="number"
              step="10"
              value={getInputValue(currentSetup.aero.frontDownforce)}
              onChange={(e) => handleChange('aero', 'frontDownforce', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Downforce (lbs)
            </label>
            <input
              type="number"
              step="10"
              value={getInputValue(currentSetup.aero.rearDownforce)}
              onChange={(e) => handleChange('aero', 'rearDownforce', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Wing Angle (°)
            </label>
            <input
              type="number"
              step="0.5"
              value={getInputValue(currentSetup.aero.wingAngle)}
              onChange={(e) => handleChange('aero', 'wingAngle', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Splitter Height (mm)
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.aero.splitterHeight)}
              onChange={(e) => handleChange('aero', 'splitterHeight', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Ride Height (mm)
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.aero.rideHeight)}
              onChange={(e) => handleChange('aero', 'rideHeight', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
        </div>
      </SetupSection>

      {/* Brakes Section */}
      <SetupSection
        title="Brakes"
        isOpen={openSections.brakes}
        onToggle={() => toggleSection('brakes')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Brake Bias (%)
            </label>
            <input
              type="number"
              step="0.1"
              min="0"
              max="100"
              value={getInputValue(currentSetup.brakes.brakeBias)}
              onChange={(e) => handleChange('brakes', 'brakeBias', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Front Brake Pressure (psi)
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.brakes.frontPressure)}
              onChange={(e) => handleChange('brakes', 'frontPressure', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Rear Brake Pressure (psi)
            </label>
            <input
              type="number"
              step="1"
              value={getInputValue(currentSetup.brakes.rearPressure)}
              onChange={(e) => handleChange('brakes', 'rearPressure', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Pad Type
            </label>
            <input
              type="text"
              value={getInputValue(currentSetup.brakes.padType)}
              onChange={(e) => handleChange('brakes', 'padType', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
        </div>
      </SetupSection>

      {/* Differential Section */}
      <SetupSection
        title="Differential"
        isOpen={openSections.differential}
        onToggle={() => toggleSection('differential')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {currentSetup.differential.frontPreload !== undefined && (
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Preload (Nm)
              </label>
              <input
                type="number"
                step="1"
                value={getInputValue(currentSetup.differential.frontPreload)}
                onChange={(e) => handleChange('differential', 'frontPreload', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          )}
          {currentSetup.differential.rearPreload !== undefined && (
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Preload (Nm)
              </label>
              <input
                type="number"
                step="1"
                value={getInputValue(currentSetup.differential.rearPreload)}
                onChange={(e) => handleChange('differential', 'rearPreload', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          )}
          {currentSetup.differential.lockingPercentage !== undefined && (
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Locking Percentage (%)
              </label>
              <input
                type="number"
                step="1"
                min="0"
                max="100"
                value={getInputValue(currentSetup.differential.lockingPercentage)}
                onChange={(e) => handleChange('differential', 'lockingPercentage', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          )}
        </div>
      </SetupSection>

      {/* Gearing Section */}
      <SetupSection
        title="Gearing"
        isOpen={openSections.gearing}
        onToggle={() => toggleSection('gearing')}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Final Drive Ratio
            </label>
            <input
              type="number"
              step="0.01"
              value={getInputValue(currentSetup.gearing.finalDrive)}
              onChange={(e) => handleChange('gearing', 'finalDrive', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Gear Ratios
            </label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {currentSetup.gearing.gearRatios.map((ratio, index) => (
                <div key={index}>
                  <label className="block text-xs text-gray-400 mb-1">
                    Gear {index + 1}
                  </label>
                  <input
                    type="number"
                    step="0.001"
                    value={getInputValue(ratio)}
                    onChange={(e) => {
                      const newRatios = [...currentSetup.gearing.gearRatios];
                      newRatios[index] = Number(e.target.value);
                      handleChange('gearing', 'gearRatios', newRatios);
                    }}
                    className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </SetupSection>

      {/* Weight Section */}
      <SetupSection
        title="Weight"
        isOpen={openSections.weight}
        onToggle={() => toggleSection('weight')}
      >
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Corner Weights (lbs)
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Front Left
                </label>
                <input
                  type="number"
                  step="1"
                  value={getInputValue(currentSetup.weight.cornerWeights.frontLeft)}
                  onChange={(e) => handleChange('weight', 'cornerWeights.frontLeft', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Front Right
                </label>
                <input
                  type="number"
                  step="1"
                  value={getInputValue(currentSetup.weight.cornerWeights.frontRight)}
                  onChange={(e) => handleChange('weight', 'cornerWeights.frontRight', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Rear Left
                </label>
                <input
                  type="number"
                  step="1"
                  value={getInputValue(currentSetup.weight.cornerWeights.rearLeft)}
                  onChange={(e) => handleChange('weight', 'cornerWeights.rearLeft', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Rear Right
                </label>
                <input
                  type="number"
                  step="1"
                  value={getInputValue(currentSetup.weight.cornerWeights.rearRight)}
                  onChange={(e) => handleChange('weight', 'cornerWeights.rearRight', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Ballast Weight (lbs)
              </label>
              <input
                type="number"
                step="1"
                value={getInputValue(currentSetup.weight.ballast)}
                onChange={(e) => handleChange('weight', 'ballast', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Ballast Position
              </label>
              <input
                type="text"
                value={getInputValue(currentSetup.weight.ballastPosition)}
                onChange={(e) => handleChange('weight', 'ballastPosition', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front/Rear Distribution (%)
              </label>
              <input
                type="number"
                step="0.1"
                min="0"
                max="100"
                value={getInputValue(currentSetup.weight.distribution.frontToRear)}
                onChange={(e) => handleChange('weight', 'distribution.frontToRear', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Left/Right Distribution (%)
              </label>
              <input
                type="number"
                step="0.1"
                min="0"
                max="100"
                value={getInputValue(currentSetup.weight.distribution.leftToRight)}
                onChange={(e) => handleChange('weight', 'distribution.leftToRight', e.target.value)}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>
          </div>
        </div>
      </SetupSection>

      {/* Tires Section */}
      <SetupSection
        title="Tires"
        isOpen={openSections.tires}
        onToggle={() => toggleSection('tires')}
      >
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Compound
            </label>
            <input
              type="text"
              value={getInputValue(currentSetup.tires.compound)}
              onChange={(e) => handleChange('tires', 'compound', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Tire Pressures (psi)
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Front Cold
                </label>
                <input
                  type="number"
                  step="0.5"
                  value={getInputValue(currentSetup.tires.frontPressureCold)}
                  onChange={(e) => handleChange('tires', 'frontPressureCold', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Front Hot
                </label>
                <input
                  type="number"
                  step="0.5"
                  value={getInputValue(currentSetup.tires.frontPressureHot)}
                  onChange={(e) => handleChange('tires', 'frontPressureHot', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Rear Cold
                </label>
                <input
                  type="number"
                  step="0.5"
                  value={getInputValue(currentSetup.tires.rearPressureCold)}
                  onChange={(e) => handleChange('tires', 'rearPressureCold', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-400 mb-1">
                  Rear Hot
                </label>
                <input
                  type="number"
                  step="0.5"
                  value={getInputValue(currentSetup.tires.rearPressureHot)}
                  onChange={(e) => handleChange('tires', 'rearPressureHot', e.target.value)}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                />
              </div>
            </div>
          </div>
        </div>
      </SetupSection>
      {/* Save Button - Moved to bottom and full width */}
      <div className="pt-8 border-t border-dark-100">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSaving ? (
            <>
              <Loader2 className="w-4 h-4 animate-spin" />
              Saving Setup...
            </>
          ) : (
            <>
              <Save className="w-4 h-4" />
              Save Setup
            </>
          )}

        </button>
        {error && (
          <p className="mt-2 text-sm text-red-500 text-center">{error}</p>
        )}
      </div>
    </div>
  );
}