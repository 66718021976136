import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Lightbulb, ChevronDown, ChevronUp } from 'lucide-react';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import TaskBoard from '../components/tasks/TaskBoard';
import TaskInsights from '../components/tasks/TaskInsights';
import type { Task, TaskStatus } from '../types/task';
import { toast } from 'react-hot-toast';
import { Dispatch, SetStateAction } from 'react';

interface TasksProps {
  tasks: Task[];
  setTasks: Dispatch<SetStateAction<Task[]>>;
}

export default function Tasks({ tasks, setTasks }: TasksProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [showInsights, setShowInsights] = useState(false);

  const handleStatusChange = async (taskId: string, newStatus: TaskStatus) => {
    if (!programId) return;

    try {
      const taskRef = doc(db, 'programs', programId, 'tasks', taskId);
      await updateDoc(taskRef, {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });
      
      // Optimistic update
      setTasks(prevTasks => 
        prevTasks.map(task => 
          task.id === taskId 
            ? { ...task, status: newStatus, updatedAt: new Date().toISOString() }
            : task
        )
      );
      
      toast.success('Task status updated');
    } catch (err) {
      console.error('Error updating task status:', err);
      toast.error('Failed to update task status');
    }
  };

  const handleTaskReorder = async (items: Task[]) => {
    if (!programId) return;

    try {
      const batch = writeBatch(db);
      
      items.forEach((task, index) => {
        const taskRef = doc(db, 'programs', programId, 'tasks', task.id);
        batch.update(taskRef, {
          order: index,
          updatedAt: new Date().toISOString()
        });
      });

      // Optimistic update
      setTasks(items);

      await batch.commit();
    } catch (err) {
      console.error('Error reordering tasks:', err);
      toast.error('Failed to reorder tasks');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Tasks</h1>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowInsights(!showInsights)}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 relative group"
          >
            <Lightbulb className={`w-4 h-4 ${showInsights ? 'text-primary-500' : 'text-gray-400'}`} />
            <span className="text-sm">Insights</span>
            {showInsights ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="absolute hidden group-hover:block right-0 top-full mt-2 w-48 p-2 bg-dark-100 text-xs text-gray-400 rounded-md z-10">
              View AI-powered task insights and suggestions
            </span>
          </button>
          <button
            onClick={() => navigate('/tasks/new')}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Task
          </button>
        </div>
      </div>

      {/* Insights Panel */}
      <div
        className={`bg-dark-200 rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
          showInsights ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="p-6">
          <TaskInsights tasks={tasks} />
        </div>
      </div>

      <TaskBoard
        tasks={tasks}
        onStatusChange={handleStatusChange}
        onReorder={handleTaskReorder}
      />
    </div>
  );
}