import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import type { ExpenseCategory } from '../../types/budget';

interface ExpenseBreakdownProps {
  data: Array<{ name: string; value: number }>;
  colors: Record<ExpenseCategory, string>;
}

export function ExpenseBreakdown({ data, colors }: ExpenseBreakdownProps) {
  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <h2 className="text-lg font-semibold text-white mb-4">Expense Breakdown</h2>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
            >
              {data.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={colors[entry.name as ExpenseCategory] || '#6B7280'} 
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: '#1F2937',
                border: 'none',
                borderRadius: '0.375rem',
                color: '#F3F4F6'
              }}
              formatter={(value: number) => `$${value.toLocaleString()}`}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}