import React from 'react';
import { IconProps } from './types';

export interface DynamicIconProps extends IconProps {
  name: string;
}

export const Icon: React.FC<DynamicIconProps> = ({ name, size = 24, className = '', ...props }) => {
  const [svgContent, setSvgContent] = React.useState<string>('');

  React.useEffect(() => {
    const loadIcon = async () => {
      try {
        const response = await fetch(`/icons/${name}.svg`);
        const text = await response.text();
        // Replace fill="black" with fill="currentColor" to support theming
        const content = text.replace(/fill="(#000000|#000|black)"/g, 'fill="currentColor"');
        setSvgContent(content);
      } catch (error) {
        console.error(`Failed to load icon: ${name}`, error);
      }
    };

    loadIcon();
  }, [name]);

  // Create a wrapper div and set innerHTML
  return (
    <div 
      className={`inline-block ${className}`}
      style={{ width: size, height: size }}
      dangerouslySetInnerHTML={{ __html: svgContent }}
      {...props}
    />
  );
}; 