import React, { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ArrowLeft, Calendar, MapPin, Car, Clock, Edit2, X, Check, ChevronDown, ChevronUp, Trash2 } from 'lucide-react';
import { format, differenceInDays } from 'date-fns';
import type { TrackEvent, EventType, EventStatus } from '../types/event';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface EventDetailProps {
  events: TrackEvent[];
  onUpdateEvent: (event: TrackEvent) => Promise<void>;
}

export default function EventDetail({ events, onUpdateEvent }: EventDetailProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { programId } = useAuth();
  
  // Check if we're creating a new event from location state
  const isNew = location.state?.isNew === true;
  
  // For new events, create a default event with the passed date or current date
  const defaultEvent: TrackEvent = {
    title: '',
    type: 'race',
    status: 'planned',
    startDate: location.state?.date ? new Date(location.state.date) : new Date(),
    endDate: location.state?.date ? new Date(location.state.date) : new Date(),
    budget: {
      estimated: 0,
      expenses: [],
      currency: 'USD'
    }
  };

  // Use defaultEvent if we're creating a new event, otherwise find the existing event
  const event = isNew ? defaultEvent : events.find(e => e.id === id);
  const [isEditing, setIsEditing] = useState(isNew);
  const [showExpenses, setShowExpenses] = useState(false);
  const [editedEvent, setEditedEvent] = useState<TrackEvent | null>(isNew ? defaultEvent : null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // Only show "Event not found" if we're looking for an existing event and can't find it
  if (!isNew && !event) {
    return (
      <div className="p-6">
        <div className="text-center text-gray-400">Event not found</div>
      </div>
    );
  }

  const handleStartEdit = () => {
    setEditedEvent(event);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    if (isNew) {
      navigate('/schedule');
    } else {
      setEditedEvent(null);
      setIsEditing(false);
    }
  };

  const handleSaveEdit = async () => {
    if (!editedEvent) return;
    
    try {
      await onUpdateEvent(editedEvent);
      toast.success(isNew ? 'Event created successfully' : 'Event updated successfully');
      navigate('/schedule');
    } catch (error) {
      toast.error(isNew ? 'Failed to create event' : 'Failed to update event');
    }
  };

  const handleDelete = async () => {
    if (!programId || !event.id) return;

    try {
      await deleteDoc(doc(db, 'programs', programId, 'events', event.id));
      toast.success('Event deleted successfully');
      navigate('/schedule');
    } catch (error) {
      toast.error('Failed to delete event');
    }
  };

  const eventDuration = differenceInDays(event.endDate, event.startDate) + 1;

  return (
    <>
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate('/schedule')}
              className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
            {isEditing ? (
              <input
                type="text"
                value={editedEvent?.title}
                onChange={(e) => setEditedEvent(prev => prev ? {...prev, title: e.target.value} : null)}
                className="text-2xl font-bold bg-dark-300 border border-dark-50 rounded px-3 py-1 text-white"
                placeholder="Enter event title"
              />
            ) : (
              <h1 className="text-2xl font-bold text-white">{event.title || 'New Event'}</h1>
            )}
          </div>
          <div className="flex items-center gap-2">
            {isEditing ? (
              <>
                <button
                  onClick={handleCancelEdit}
                  className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
                >
                  <X className="w-4 h-4" />
                  Cancel
                </button>
                <button
                  onClick={handleSaveEdit}
                  className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
                >
                  <Check className="w-4 h-4" />
                  {isNew ? 'Create Event' : 'Save Changes'}
                </button>
              </>
            ) : (
              <>
                {!isNew && (
                  <button
                    onClick={() => setShowDeleteConfirm(true)}
                    className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-red-500 rounded-md hover:bg-dark-50"
                  >
                    <Trash2 className="w-4 h-4" />
                    Delete Event
                  </button>
                )}
                <button
                  onClick={handleStartEdit}
                  className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
                >
                  <Edit2 className="w-4 h-4" />
                  Edit Event
                </button>
              </>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 space-y-6">
            {/* Event Details */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="grid grid-cols-2 gap-6">
                {/* Event Type */}
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Event Type</label>
                  {isEditing ? (
                    <select
                      value={editedEvent?.type}
                      onChange={(e) => setEditedEvent(prev => prev ? {...prev, type: e.target.value as EventType} : null)}
                      className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                    >
                      <option value="race">Race</option>
                      <option value="practice">Practice</option>
                      <option value="maintenance">Testing</option>
                      <option value="other">Other</option>
                    </select>
                  ) : (
                    <div className="text-white capitalize">{event.type}</div>
                  )}
                </div>

                {/* Event Status */}
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Status</label>
                  {isEditing ? (
                    <select
                      value={editedEvent?.status}
                      onChange={(e) => setEditedEvent(prev => prev ? {...prev, status: e.target.value as EventStatus} : null)}
                      className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                    >
                      <option value="planned">Planned</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="completed">Completed</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  ) : (
                    <div className="text-white capitalize">{event.status}</div>
                  )}
                </div>

                {/* Date Range */}
                <div className="col-span-2">
                  <label className="block text-sm text-gray-400 mb-2">Date Range</label>
                  {isEditing ? (
                    <div className="flex items-center gap-4">
                      <input
                        type="date"
                        value={editedEvent?.startDate.toISOString().split('T')[0]}
                        onChange={(e) => setEditedEvent(prev => prev ? {...prev, startDate: new Date(e.target.value)} : null)}
                        className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                      />
                      <span className="text-gray-400">to</span>
                      <input
                        type="date"
                        value={editedEvent?.endDate.toISOString().split('T')[0]}
                        onChange={(e) => setEditedEvent(prev => prev ? {...prev, endDate: new Date(e.target.value)} : null)}
                        className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center gap-2 text-white">
                      <Calendar className="w-4 h-4 text-primary-500" />
                      {format(event.startDate, 'MMM d, yyyy')}
                      {eventDuration > 1 && (
                        <>
                          <span className="text-gray-400 mx-2">→</span>
                          {format(event.endDate, 'MMM d, yyyy')}
                          <span className="text-gray-400 ml-2">({eventDuration} days)</span>
                        </>
                      )}
                    </div>
                  )}
                </div>

                {/* Location */}
                <div className="col-span-2">
                  <label className="block text-sm text-gray-400 mb-2">Location</label>
                  {isEditing ? (
                    <input
                      type="text"
                      value={typeof editedEvent?.location === 'string' ? editedEvent.location : editedEvent?.location?.name || ''}
                      onChange={(e) => setEditedEvent(prev => prev ? {...prev, location: e.target.value} : null)}
                      className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                      placeholder="Enter location"
                    />
                  ) : (
                    <div className="flex items-center gap-2 text-white">
                      <MapPin className="w-4 h-4 text-primary-500" />
                      {typeof event.location === 'string' ? event.location : event.location?.name || 'No location set'}
                    </div>
                  )}
                </div>

                {/* Description */}
                <div className="col-span-2">
                  <label className="block text-sm text-gray-400 mb-2">Description</label>
                  {isEditing ? (
                    <textarea
                      value={editedEvent?.description || ''}
                      onChange={(e) => setEditedEvent(prev => prev ? {...prev, description: e.target.value} : null)}
                      className="w-full h-32 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                      placeholder="Add event description..."
                    />
                  ) : (
                    <div className="text-white whitespace-pre-wrap">
                      {event.description || 'No description'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Budget Overview */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold text-white">Budget</h2>
                <button
                  onClick={() => setShowExpenses(!showExpenses)}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  {showExpenses ? (
                    <>
                      Hide Details
                      <ChevronUp className="w-4 h-4" />
                    </>
                  ) : (
                    <>
                      Show Details
                      <ChevronDown className="w-4 h-4" />
                    </>
                  )}
                </button>
              </div>

              <div className="text-2xl font-semibold text-white mb-2">
                ${event.budget?.estimated || 0}
                <span className="text-sm text-gray-400 ml-1">estimated</span>
              </div>

              {showExpenses && (
                <div className="mt-4 space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium text-gray-400">Expenses</h3>
                    <button
                      onClick={() => {/* TODO: Add expense */}}
                      className="text-sm text-primary-500 hover:text-primary-400"
                    >
                      Add Expense
                    </button>
                  </div>

                  {event.budget?.expenses && event.budget.expenses.length > 0 ? (
                    <div className="space-y-2">
                      {event.budget.expenses.map((expense, index) => (
                        <div key={index} className="flex justify-between items-center p-2 bg-dark-100 rounded">
                          <div>
                            <div className="text-white">{expense.description}</div>
                            <div className="text-sm text-gray-400">{expense.category}</div>
                          </div>
                          <div className="text-white">${expense.amount * expense.quantity}</div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-gray-400 py-4">
                      No expenses added yet
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full">
            <h2 className="text-xl font-bold text-white mb-4">Delete Event</h2>
            <p className="text-gray-400 mb-6">
              Are you sure you want to delete "{event.title}"? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-400 hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                <Trash2 className="w-4 h-4" />
                Delete Event
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}