import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Flag, FlagOff, FlagTriangleRight } from 'lucide-react';
import SortableTaskCard from './SortableTaskCard';
import { Task, TaskStatus } from '../../types/task';

interface TaskColumnProps {
  id: TaskStatus;
  title: string;
  tasks: Task[];
}

const getStatusIcon = (status: TaskStatus) => {
  switch (status) {
    case 'pit-stop':
      return <FlagOff className="w-5 h-5 text-red-500" />;
    case 'on-track':
      return <FlagTriangleRight className="w-5 h-5 text-blue-500" />;
    case 'done':
      return <Flag className="w-5 h-5 text-green-500" />;
    default:
      return <Flag className="w-5 h-5 text-gray-500" />;
  }
};

export default function TaskColumn({ id, title, tasks }: TaskColumnProps) {
  const { setNodeRef } = useDroppable({ id });

  return (
    <div
      ref={setNodeRef}
      className="bg-dark-200 rounded-lg p-4 min-h-[500px]"
    >
      <div className="flex items-center gap-2 mb-4">
        {getStatusIcon(id)}
        <h3 className="text-lg font-semibold text-white">{title}</h3>
        <span className="text-sm text-gray-400">({tasks.length})</span>
      </div>

      <SortableContext
        items={tasks.map(t => t.id)}
        strategy={verticalListSortingStrategy}
      >
        <div className="space-y-3">
          {tasks.map(task => (
            <SortableTaskCard key={task.id} task={task} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}