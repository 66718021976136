import React from 'react';
import { AlertTriangle, TrendingUp, Clock, Tag } from 'lucide-react';
import type { Task } from '../../types/task';

interface TaskInsightsProps {
  tasks: Task[];
}

export default function TaskInsights({ tasks }: TaskInsightsProps) {
  // Calculate task statistics
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(t => t.status === 'done').length;
  const highPriorityTasks = tasks.filter(t => t.priority >= 6 && t.status !== 'done').length;
  const overdueTasks = tasks.filter(t => {
    if (!t.dueDate || t.status === 'done') return false;
    return new Date(t.dueDate) < new Date();
  }).length;

  // Get most common labels
  const labelCounts = tasks.reduce((acc, task) => {
    task.labels?.forEach(label => {
      acc[label] = (acc[label] || 0) + 1;
    });
    return acc;
  }, {} as Record<string, number>);

  const topLabels = Object.entries(labelCounts)
    .sort(([,a], [,b]) => b - a)
    .slice(0, 3);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Task Statistics */}
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-400">Completion Rate</span>
          <span className="text-white font-medium">
            {totalTasks ? Math.round((completedTasks / totalTasks) * 100) : 0}%
          </span>
        </div>
        <div className="h-2 bg-dark-300 rounded-full overflow-hidden">
          <div 
            className="h-full bg-primary-500 rounded-full transition-all duration-300"
            style={{ width: `${totalTasks ? (completedTasks / totalTasks) * 100 : 0}%` }}
          />
        </div>
      </div>

      {/* AI Insights */}
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          {/* Priority Distribution */}
          <div className="flex items-start gap-3">
            <AlertTriangle className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
            <div>
              <p className="text-gray-300">
                {highPriorityTasks} high-priority tasks require attention
              </p>
              <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                View suggested order
              </button>
            </div>
          </div>

          {/* Task Categories */}
          <div className="flex items-start gap-3">
            <Tag className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
            <div>
              <p className="text-gray-300">
                Most common: {topLabels.map(([label]) => label).join(', ')}
              </p>
              <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                Optimize categories
              </button>
            </div>
          </div>

          {/* Overdue Tasks */}
          {overdueTasks > 0 && (
            <div className="flex items-start gap-3">
              <Clock className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
              <div>
                <p className="text-gray-300">
                  {overdueTasks} tasks past due date
                </p>
                <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                  Generate catch-up plan
                </button>
              </div>
            </div>
          )}

          {/* Efficiency Suggestions */}
          <div className="flex items-start gap-3">
            <TrendingUp className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
            <div>
              <p className="text-gray-300">
                Group similar tasks to improve efficiency
              </p>
              <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                View suggestions
              </button>
            </div>
          </div>
        </div>

        {/* Preview Message */}
        <div className="text-xs text-gray-500">
          AI insights are in preview. Analysis will use historical data and relationships when available.
        </div>
      </div>
    </div>
  );
}