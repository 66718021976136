import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader2, Search, Wand2 } from 'lucide-react';
import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { getAllMakes, getModelsForMake } from '../../services/nhtsaService';
import { getVehicleSpecs } from '../../services/aiService';
import type { Vehicle, VehicleCategory, DrivetrainType } from '../../types/vehicle';

interface VehicleFormProps {
  isEdit?: boolean;
  onSubmit: (formData: Partial<Vehicle>) => Promise<void>;
  isSubmitting?: boolean;
}

interface Make {
  id: number;
  name: string;
}

interface Model {
  id: number;
  name: string;
}

const VEHICLE_CATEGORIES: VehicleCategory[] = [
  'Time Attack',
  'Drift',
  'Race',
  'Street',
  'Rally',
  'Endurance',
  'Autocross'
];

export default function VehicleForm({ isEdit, onSubmit, isSubmitting = false }: VehicleFormProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [error, setError] = useState('');
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [makes, setMakes] = useState<Make[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [makeSearch, setMakeSearch] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  const [selectedMakeId, setSelectedMakeId] = useState<number | null>(null);
  const [isLoadingMakes, setIsLoadingMakes] = useState(false);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const [isMakeDropdownOpen, setIsMakeDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isLoadingSpecs, setIsLoadingSpecs] = useState(false);

  const [formData, setFormData] = useState<Partial<Vehicle>>({
    name: '',
    make: '',
    model: '',
    year: new Date().getFullYear(),
    categories: [],
    weight: 0,
    power: 0,
    torque: 0,
    drivetrain: 'RWD',
    fuelType: 'E85',
    primaryUse: 'Track',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  const makeDropdownRef = useRef<HTMLDivElement>(null);
  const modelDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (makeDropdownRef.current && !makeDropdownRef.current.contains(event.target as Node)) {
        setIsMakeDropdownOpen(false);
      }
      if (modelDropdownRef.current && !modelDropdownRef.current.contains(event.target as Node)) {
        setIsModelDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function fetchMakes() {
      setIsLoadingMakes(true);
      const makesData = await getAllMakes();
      setMakes(makesData);
      setIsLoadingMakes(false);
    }
    fetchMakes();
  }, []);

  useEffect(() => {
    async function fetchModels() {
      if (selectedMakeId) {
        setIsLoadingModels(true);
        const modelsData = await getModelsForMake(selectedMakeId);
        setModels(modelsData);
        setIsLoadingModels(false);
      } else {
        setModels([]);
      }
    }
    fetchModels();
  }, [selectedMakeId]);

  useEffect(() => {
    async function fetchVehicle() {
      if (isEdit && id && programId) {
        try {
          const vehicleRef = doc(db, 'programs', programId, 'vehicles', id);
          const vehicleSnap = await getDoc(vehicleRef);
          
          if (vehicleSnap.exists()) {
            const vehicleData = { id: vehicleSnap.id, ...vehicleSnap.data() } as Vehicle;
            setVehicle(vehicleData);
            setFormData(vehicleData);
            setMakeSearch(vehicleData.make);
            setModelSearch(vehicleData.model);
            const findMake = makes.find(m => m.name === vehicleData.make);
            if (findMake) {
              setSelectedMakeId(findMake.id);
            }
          }
        } catch (err) {
          console.error('Error fetching vehicle:', err);
          setError('Failed to load vehicle data');
        }
      }
    }

    fetchVehicle();
  }, [isEdit, id, programId, makes]);

  const filteredMakes = makes
    .filter(make => make.name.toLowerCase().includes(makeSearch.toLowerCase()))
    .sort((a, b) => {
      const aStartsWith = a.name.toLowerCase().startsWith(makeSearch.toLowerCase());
      const bStartsWith = b.name.toLowerCase().startsWith(makeSearch.toLowerCase());
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.name.localeCompare(b.name);
    });

  const filteredModels = models
    .filter(model => model.name.toLowerCase().includes(modelSearch.toLowerCase()))
    .sort((a, b) => {
      const aStartsWith = a.name.toLowerCase().startsWith(modelSearch.toLowerCase());
      const bStartsWith = b.name.toLowerCase().startsWith(modelSearch.toLowerCase());
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.name.localeCompare(b.name);
    });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      await onSubmit(formData);
    } catch (err) {
      console.error('Error in form submission:', err);
      setError('Failed to save vehicle');
    }
  };

  const handleCategoryToggle = (category: VehicleCategory) => {
    setFormData(prev => {
      const currentCategories = prev.categories || [];
      const newCategories = currentCategories.includes(category)
        ? currentCategories.filter(c => c !== category)
        : [...currentCategories, category];
      
      return {
        ...prev,
        categories: newCategories
      };
    });
  };

  const handleDrivetrainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as DrivetrainType;
    setFormData(prev => ({ ...prev, drivetrain: value }));
  };

  const handleMakeSelect = (make: Make) => {
    setFormData(prev => ({ ...prev, make: make.name }));
    setSelectedMakeId(make.id);
    setMakeSearch(make.name);
    setIsMakeDropdownOpen(false);
  };

  const handleModelSelect = (model: Model) => {
    setFormData(prev => ({ ...prev, model: model.name }));
    setModelSearch(model.name);
    setIsModelDropdownOpen(false);
  };

  const handleAutoFillSpecs = async () => {
    if (!formData.year || !formData.make || !formData.model) {
      setError('Please fill in year, make, and model first');
      return;
    }

    setIsLoadingSpecs(true);
    try {
      const specs = await getVehicleSpecs(
        formData.year,
        formData.make,
        formData.model,
        formData.trim
      );

      if (specs) {
        setFormData(prev => ({
          ...prev,
          power: specs.power,
          weight: specs.weight,
          drivetrain: specs.drivetrain
        }));
      }
    } catch (error) {
      console.error('Error auto-filling specs:', error);
      setError('Failed to auto-fill specifications');
    } finally {
      setIsLoadingSpecs(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      {error && (
        <div className="bg-red-500/10 text-red-500 p-3 rounded-md">
          {error}
        </div>
      )}

      {/* Vehicle Identity */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2">
          <h2 className="text-lg font-semibold text-white">Vehicle Identity</h2>
          <div className="flex-1 h-px bg-gradient-to-r from-primary-500/50 to-transparent" />
        </div>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Name/Nickname
            </label>
            <input
              type="text"
              value={formData.name || ''}
              onChange={e => setFormData(prev => ({ 
                ...prev, 
                name: e.target.value.slice(0, 200) 
              }))}
              maxLength={200}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              placeholder="e.g., Track Monster"
            />
            <div className="mt-1 text-xs text-gray-500 flex justify-end">
              {(formData.name?.length || 0)}/200
            </div>
          </div>
        </div>
      </div>

      {/* Basic Information */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2">
          <h2 className="text-lg font-semibold text-white">Basic Information</h2>
          <div className="flex-1 h-px bg-gradient-to-r from-primary-500/50 to-transparent" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="relative" ref={makeDropdownRef}>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Make
            </label>
            <div className="relative">
              <input
                type="text"
                value={makeSearch}
                onChange={(e) => {
                  setMakeSearch(e.target.value);
                  setIsMakeDropdownOpen(true);
                }}
                onFocus={() => setIsMakeDropdownOpen(true)}
                placeholder="Search makes..."
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
              <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            {isMakeDropdownOpen && makeSearch && (
              <div className="absolute z-10 w-full mt-1 bg-dark-200 rounded-md shadow-lg max-h-60 overflow-auto">
                {isLoadingMakes ? (
                  <div className="p-2 text-center">
                    <Loader2 className="w-5 h-5 animate-spin mx-auto" />
                  </div>
                ) : (
                  filteredMakes.map(make => (
                    <button
                      key={make.id}
                      type="button"
                      onClick={() => handleMakeSelect(make)}
                      className="w-full text-left px-4 py-2 text-sm text-white hover:bg-dark-100"
                    >
                      {make.name}
                    </button>
                  ))
                )}
              </div>
            )}
          </div>

          <div className="relative" ref={modelDropdownRef}>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Model
            </label>
            <div className="relative">
              <input
                type="text"
                value={modelSearch}
                onChange={(e) => {
                  setModelSearch(e.target.value);
                  setIsModelDropdownOpen(true);
                }}
                onFocus={() => setIsModelDropdownOpen(true)}
                placeholder="Search models..."
                disabled={!selectedMakeId}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
              <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            {isModelDropdownOpen && modelSearch && selectedMakeId && (
              <div className="absolute z-10 w-full mt-1 bg-dark-200 rounded-md shadow-lg max-h-60 overflow-auto">
                {isLoadingModels ? (
                  <div className="p-2 text-center">
                    <Loader2 className="w-5 h-5 animate-spin mx-auto" />
                  </div>
                ) : (
                  filteredModels.map(model => (
                    <button
                      key={model.id}
                      type="button"
                      onClick={() => handleModelSelect(model)}
                      className="w-full text-left px-4 py-2 text-sm text-white hover:bg-dark-100"
                    >
                      {model.name}
                    </button>
                  ))
                )}
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Trim/Package
            </label>
            <input
              type="text"
              value={formData.trim || ''}
              onChange={e => setFormData(prev => ({ 
                ...prev, 
                trim: e.target.value.slice(0, 200) 
              }))}
              placeholder="e.g., GT Performance Package"
              maxLength={200}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Year
            </label>
            <input
              type="number"
              value={formData.year || ''}
              onChange={e => setFormData(prev => ({ ...prev, year: parseInt(e.target.value) }))}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              required
            />
          </div>
        </div>
      </div>

      {/* Performance Specifications */}
      <div className="space-y-4">
        <div className="flex items-center justify-between gap-2 pb-2">
          <div className="flex items-center gap-2">
            <h2 className="text-lg font-semibold text-white">Performance Specifications</h2>
            <div className="flex-1 h-px bg-gradient-to-r from-primary-500/50 to-transparent" />
          </div>
          <button
            type="button"
            onClick={handleAutoFillSpecs}
            disabled={isLoadingSpecs || !formData.make || !formData.model || !formData.year}
            className="flex items-center gap-2 px-3 py-1.5 text-sm bg-dark-200 text-gray-300 rounded-md hover:bg-dark-100 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            {isLoadingSpecs ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <Wand2 className="w-4 h-4" />
            )}
            Auto-fill Specs
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Power (HP)
            </label>
            <input
              type="number"
              value={formData.power || ''}
              onChange={e => setFormData(prev => ({ ...prev, power: parseInt(e.target.value) }))}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Weight (lbs)
            </label>
            <input
              type="number"
              value={formData.weight || ''}
              onChange={e => setFormData(prev => ({ ...prev, weight: parseInt(e.target.value) }))}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Drivetrain
            </label>
            <select
              value={formData.drivetrain || 'RWD'}
              onChange={handleDrivetrainChange}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
            >
              <option value="FWD">FWD</option>
              <option value="RWD">RWD</option>
              <option value="AWD">AWD</option>
              <option value="4WD">4WD</option>
            </select>
          </div>
        </div>
      </div>

      {/* Vehicle Usage */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 pb-2">
          <h2 className="text-lg font-semibold text-white">Vehicle Usage</h2>
          <div className="flex-1 h-px bg-gradient-to-r from-primary-500/50 to-transparent" />
        </div>
        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-400 mb-2">
            Categories
          </label>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {VEHICLE_CATEGORIES.map(category => (
              <label
                key={category}
                className="relative flex items-center gap-2 p-3 rounded-md bg-dark-200 hover:bg-dark-100 cursor-pointer group"
              >
                <input
                  type="checkbox"
                  checked={(formData.categories || []).includes(category)}
                  onChange={() => handleCategoryToggle(category)}
                  className="hidden"
                />
                <div className={`
                  w-4 h-4 rounded border transition-colors
                  ${(formData.categories || []).includes(category)
                    ? 'bg-primary-500 border-primary-500'
                    : 'border-gray-500 group-hover:border-primary-500'
                  }
                `}>
                  {(formData.categories || []).includes(category) && (
                    <svg
                      className="w-4 h-4 text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  )}
                </div>
                <span className="text-sm text-white">{category}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="flex justify-end gap-4 pt-4 border-t border-dark-50">
        <button
          type="button"
          onClick={() => navigate('/garage')}
          className="px-4 py-2 text-gray-400 hover:text-white"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="flex items-center gap-2 px-6 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
        >
          {isSubmitting ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : null}
          {isEdit ? 'Update Vehicle' : 'Add Vehicle'}
        </button>
      </div>
    </form>
  );
}