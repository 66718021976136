import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, Tag, AlertTriangle } from 'lucide-react';
import { Task } from '../../types/task';
import { format } from 'date-fns';

interface TaskCardProps {
  task: Task;
}

export default function TaskCard({ task }: TaskCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/tasks/${task.id}`);
  };

  return (
    <div
      onClick={handleClick}
      className="block bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors cursor-pointer"
    >
      <div className="flex justify-between items-start gap-4">
        <h4 className="text-white font-medium">{task.title}</h4>
        {task.priority >= 6 && (
          <AlertTriangle className="w-4 h-4 text-red-400 shrink-0" />
        )}
      </div>
      
      {task.description && (
        <p className="text-sm text-gray-400 mt-2 mb-3 line-clamp-2">
          {task.description}
        </p>
      )}

      <div className="space-y-3">
        {task.dueDate && (
          <div className="flex items-center gap-2 text-sm text-gray-400">
            <Calendar className="w-4 h-4" />
            <span>{format(new Date(task.dueDate), 'MMM d, yyyy')}</span>
          </div>
        )}

        {task.labels && task.labels.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {task.labels.map(label => (
              <div
                key={label}
                className="flex items-center gap-1 px-2 py-1 rounded-full bg-dark-200 text-xs text-gray-400"
              >
                <Tag className="w-3 h-3" />
                {label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}