import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types/auth';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  onAuthStateChanged, 
  signOut,
  deleteUser,
  getAuth
} from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, setDoc, getDoc, addDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { redirectToCheckout } from '../services/stripeService';

console.log('AuthContext file is being loaded');

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  isPayingCustomer: boolean;
  programId: string | null;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  deleteAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  console.log('AuthProvider is rendering');

  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPayingCustomer, setIsPayingCustomer] = useState(false);
  const [programId, setProgramId] = useState<string | null>(null);

  useEffect(() => {
    console.log('AuthProvider mounted');
    let unsubscribe: () => void;

    const handleAuth = async () => {
      try {
        // Set up auth state listener
        unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
          console.log('Auth state changed:', firebaseUser);
          if (firebaseUser) {
            try {
              const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
              const userData = userDoc.data();

              if (userData) {
                // Get the default program ID from the programs array
                const defaultProgramId = userData.programs?.[0] || null;
                setProgramId(defaultProgramId);

                const isActive = userData.subscription?.status === 'active';
                
                // If they have an active subscription, make sure their status is updated
                if (isActive && userData.status === 'pending') {
                  await setDoc(doc(db, 'users', firebaseUser.uid), {
                    ...userData,
                    status: 'active'
                  }, { merge: true });
                  
                  userData.status = 'active'; // Update local userData
                }

                setUser({
                  id: firebaseUser.uid,
                  email: firebaseUser.email || '',
                  displayName: firebaseUser.displayName || '',
                  photoURL: firebaseUser.photoURL || '',
                  status: userData.status || 'active',
                  subscription: userData.subscription,
                });

                setIsPayingCustomer(isActive);
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          } else {
            setUser(null);
            setProgramId(null);
            setIsPayingCustomer(false);
          }
          setIsLoading(false);
        });
      } catch (error) {
        console.error('Auth error:', error);
        setIsLoading(false);
      }
    };

    handleAuth();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const loginWithGoogle = async () => {
    console.log('Initiating Google sign-in');
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    
    try {
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      
      console.log('Starting Google popup...');
      const result = await signInWithPopup(auth, provider);
      console.log('Google sign-in successful:', result.user.uid);
      
      // Create initial user document if it doesn't exist
      const userRef = doc(db, 'users', result.user.uid);
      const userSnap = await getDoc(userRef);
      console.log('Checked for existing user document:', userSnap.exists());
      
      if (!userSnap.exists()) {
        console.log('Creating new user document...');
        await setDoc(userRef, {
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          createdAt: new Date(),
          status: 'pending',
          programs: []
        });
        console.log('User document created');

        console.log('Creating new program...');
        // Create the program document
        const programRef = await addDoc(collection(db, 'programs'), {
          createdAt: new Date(),
          ownerId: result.user.uid
        });
        console.log('Created program:', programRef.id);

        // Update user with program ID
        await updateDoc(userRef, {
          programs: [programRef.id]
        });
        console.log('Updated user with program ID');

        // Set the user state immediately for new users
        setUser({
          id: result.user.uid,
          email: result.user.email || '',
          displayName: result.user.displayName || '',
          photoURL: result.user.photoURL || '',
          status: 'pending',
          subscription: undefined,
        });
        setProgramId(programRef.id);
        setIsPayingCustomer(false);

        // Redirect to pricing page for new users
        window.location.href = '/pricing';
      }
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAccount = async () => {
    if (!auth.currentUser) {
      throw new Error('No user signed in');
    }

    try {
      await deleteUser(auth.currentUser);
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        isLoading, 
        isPayingCustomer,
        programId,
        loginWithGoogle,
        logout,
        deleteAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}