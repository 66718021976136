import { commonParts, partGroups, PartTemplate } from '../../config/partCategories';
import type { Part } from '../../types/part';

interface PartsEmptyStateProps {
  onAddPart: (template: PartTemplate) => void;
  existingParts: Part[];
}

export function PartsEmptyState({ onAddPart, existingParts }: PartsEmptyStateProps) {
  const isPartInstalled = (template: PartTemplate) => {
    return existingParts.some(part => 
      part.category === template.category && 
      part.subcategory === template.subcategory
    );
  };

  const remainingGroups = partGroups.map(group => ({
    ...group,
    parts: group.parts.filter(partName => {
      const template = commonParts.find(p => p.name === partName);
      return template && !isPartInstalled(template);
    })
  })).filter(group => group.parts.length > 0);

  if (remainingGroups.length === 0) return null;

  return (
    <div className="space-y-8 bg-dark-200 rounded-lg p-6 mt-6">
      <div className="text-center">
        <h3 className="text-lg font-medium text-white">Recommended Parts to Track</h3>
        <p className="mt-2 text-gray-400">
          Add these components to get more from your vehicle tracking
        </p>
      </div>
      
      {remainingGroups.map(group => (
        <div key={group.title} className="space-y-4">
          <div className="border-b border-dark-100 pb-2">
            <h4 className="text-white font-medium">{group.title}</h4>
            <p className="text-sm text-gray-400">{group.description}</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {group.parts.map(partName => {
              const template = commonParts.find(p => p.name === partName);
              if (!template) return null;
              
              return (
                <button
                  key={template.name}
                  onClick={() => onAddPart(template)}
                  className="flex items-start gap-3 p-4 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors text-left"
                >
                  <div className="w-10 h-10 rounded-full bg-primary-500/10 flex items-center justify-center flex-shrink-0">
                    <span className="text-primary-500 text-lg font-semibold">{template.name[0]}</span>
                  </div>
                  <div>
                    <h5 className="text-white font-medium">{template.name}</h5>
                    <p className="text-sm text-gray-400 mt-1">{template.description}</p>
                    {template.metadata?.changeInterval && (
                      <p className="text-xs text-gray-500 mt-1">
                        Change every {template.metadata.changeInterval} {template.metadata.wearMetric}
                      </p>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
} 