import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import ChecklistCard from '../components/checklists/ChecklistCard';
import type { Checklist } from '../types/checklist';
import { Dispatch, SetStateAction } from 'react';

interface ChecklistsProps {
  checklists: Checklist[];
  setChecklists: Dispatch<SetStateAction<Checklist[]>>;
}

export default function Checklists({ checklists, setChecklists }: ChecklistsProps) {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Checklists</h1>
        <Link
          to="/checklists/new"
          className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
        >
          <Plus className="w-4 h-4" />
          New Checklist
        </Link>
      </div>

      {checklists.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-400">No checklists created yet.</p>
          <Link
            to="/checklists/new"
            className="mt-4 text-primary-500 hover:text-primary-400"
          >
            Create your first checklist
          </Link>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {checklists.map(checklist => (
            <ChecklistCard
              key={checklist.id}
              checklist={checklist}
              onEdit={() => navigate(`/checklists/${checklist.id}`)}
              onDelete={() => {
                // Delete functionality will be handled in ChecklistCard
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}