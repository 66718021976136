import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import LoadingScreen from '../common/LoadingScreen';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to="/landing" replace />;
  }

  if (user.status === 'pending') {
    return <Navigate to="/payment" replace />;
  }

  if (user.status === 'expired' || user.status === 'cancelled') {
    return <Navigate to="/subscription" replace />;
  }

  return children;
}