import React from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';

interface BudgetSummaryPanelProps {
  totals: {
    totalExpenses: number;
    totalIncome: number;
    netBalance: number;
  };
}

export function BudgetSummaryPanel({ totals }: BudgetSummaryPanelProps) {
  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <h2 className="text-lg font-semibold text-white mb-6">Budget Summary</h2>
      <div className="space-y-4">
        <div>
          <div className="flex items-center justify-between">
            <span className="text-gray-400">Total Income</span>
            <div className="flex items-center gap-2 text-green-500">
              <ArrowUpRight className="w-4 h-4" />
              <span>${totals.totalIncome.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <span className="text-gray-400">Total Expenses</span>
            <div className="flex items-center gap-2 text-red-500">
              <ArrowDownRight className="w-4 h-4" />
              <span>${totals.totalExpenses.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <div className="pt-4 border-t border-dark-50">
          <div className="flex items-center justify-between">
            <span className="text-gray-400">Net Balance</span>
            <span className={totals.netBalance >= 0 ? 'text-green-500' : 'text-red-500'}>
              ${totals.netBalance.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}