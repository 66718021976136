import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';

const stripePromise = loadStripe('pk_test_51LK6S0DXsyBV2tv6XPlpRJFuuik3ZsamoooFAbwqRYqAtOOem7Xlo9eObYQTLn03V1LPfA87eTY5Al8q16pBwIKJ00eRABBCrG');

export async function redirectToCheckout() {
  console.log('Starting checkout redirect process...');
  try {
    const stripe = await stripePromise;
    if (!stripe) throw new Error('Stripe failed to initialize');

    console.log('Stripe initialized, creating checkout session...');
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');

    console.log('Calling createCheckoutSession function...');
    const { data } = await createCheckoutSession({
      origin: window.location.origin,
    });

    const { sessionId } = data as { sessionId: string };
    console.log('Got session ID:', sessionId);
    
    // Redirect to Stripe Checkout
    console.log('Redirecting to Stripe checkout...');
    const result = await stripe.redirectToCheckout({
      sessionId,
    });

    if (result.error) {
      throw new Error(result.error.message);
    }
  } catch (error) {
    console.error('Error redirecting to checkout:', error);
    throw error;
  }
} 