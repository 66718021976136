import React, { useState, useRef } from 'react';
import { Plus, Loader2, ChevronDown, Trash2 } from 'lucide-react';
import { doc, updateDoc, addDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { getPartInfo } from '../../services/aiService';
import type { Part, PartCategory, PartSubcategory } from '../../types/part';
import { PartsEmptyState } from './PartsEmptyState';
import { AddPartForm } from './AddPartForm';
import type { PartTemplate } from '../../config/partCategories';
import { commonParts } from '../../config/partCategories';
import type { Vehicle } from '../../types/vehicle';

interface PartsTableProps {
  parts: Part[];
  vehicleId?: string;
  showQuickAdd?: boolean;
  mode: 'inventory' | 'installed';
  vehicle?: Vehicle;
}

const PART_CATEGORIES: PartCategory[] = [
  'Brakes',
  'Tires',
  'Engine',
  'Suspension',
  'Transmission',
  'Exterior',
  'Interior',
  'Electrical',
  'Cooling',
  'Fuel System',
  'Exhaust',
  'Drivetrain',
  'Other'
];

const PART_CONDITIONS = ['new', 'used', 'refurbished'] as const;

const normalizeString = (str: string) => 
  str.toLowerCase().replace(/\s+/g, '');

export default function PartsTable({ parts, vehicleId, showQuickAdd = true, mode, vehicle }: PartsTableProps) {
  const { programId } = useAuth();
  const [newPartName, setNewPartName] = useState('');
  const [expandedPartId, setExpandedPartId] = useState<string | null>(null);
  const [isAddingPart, setIsAddingPart] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState<PartTemplate | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTemplateSelect = (template: PartTemplate) => {
    setActiveSuggestion(template);
    // Scroll to input smoothly
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // Focus the input
    inputRef.current?.focus();
  };

  const handleQuickAddPart = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newPartName.trim() && programId && vehicle) {
      setIsAddingPart(true);
      try {
        const partInfo = await getPartInfo(newPartName.trim(), vehicle);
        
        const template = commonParts.find(p => 
          p.category === partInfo.category && 
          p.subcategory === partInfo.subcategory
        );
        
        const partData: Partial<Part> = {
          name: partInfo.name || newPartName.trim(),
          category: partInfo.category as PartCategory,
          subcategory: partInfo.subcategory as PartSubcategory,
          cost: partInfo.cost || 0,
          description: partInfo.description || '',
          manufacturer: partInfo.manufacturer || '',
          quantity: 1,
          condition: 'new',
          vehicleId: vehicleId,
          metadata: template?.metadata || {},
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        const partsRef = collection(db, 'programs', programId, 'parts');
        await addDoc(partsRef, partData);
        
        setNewPartName('');
        toast.success('Part added with AI-suggested details');
      } catch (err) {
        console.error('Error adding part:', err);
        toast.error('Failed to add part');
      } finally {
        setIsAddingPart(false);
      }
    }
  };

  const handleAddPart = async (formData: Partial<Part>) => {
    if (!programId) return;

    try {
      const partData: Partial<Part> = {
        ...formData,
        vehicleId: vehicleId || null,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const partsRef = collection(db, 'programs', programId, 'parts');
      await addDoc(partsRef, partData);
      
      toast.success('Part added successfully');
    } catch (err) {
      console.error('Error adding part:', err);
      toast.error('Failed to add part');
    }
  };

  const handleDeletePart = async (partId: string) => {
    if (!programId) return;
    
    try {
      const partRef = doc(db, 'programs', programId, 'parts', partId);
      await deleteDoc(partRef);
      toast.success('Part deleted successfully');
    } catch (err) {
      console.error('Error deleting part:', err);
      toast.error('Failed to delete part');
    }
  };

  const handleUpdatePart = async (partId: string, updates: Partial<Part>) => {
    if (!programId) return;
    
    try {
      const partsRef = doc(db, 'programs', programId!, 'parts', partId);
      await updateDoc(partsRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      });
    } catch (err) {
      console.error('Error updating part:', err);
      toast.error('Failed to update part');
    }
  };

  return (
    <div className="space-y-6">
      {showQuickAdd && (
        <div className="relative space-y-2">
          <div className="flex gap-2">
            <input
              ref={inputRef}
              type="text"
              value={newPartName}
              onChange={(e) => setNewPartName(e.target.value)}
              onKeyDown={handleQuickAddPart}
              placeholder="Enter part details..."
              className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              disabled={isAddingPart}
            />
            <button
              onClick={() => {
                if (newPartName.trim()) {
                  handleQuickAddPart({ key: 'Enter' } as React.KeyboardEvent<HTMLInputElement>);
                }
              }}
              disabled={isAddingPart || !newPartName.trim()}
              className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
            >
              {isAddingPart ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Plus className="w-4 h-4" />
              )}
              Add Part
            </button>
          </div>
          
          {/* Suggestion hint */}
          {activeSuggestion && (
            <div className="text-sm text-gray-400 animate-fadeIn">
              <div className="flex items-center gap-2">
                <div className="w-1 h-4 bg-primary-500 rounded-full"></div>
                <p>
                  Adding {activeSuggestion.name.toLowerCase()}? Include the brand, model, and any specific details 
                  that will help track maintenance.
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {parts.length === 0 ? (
        mode === 'installed' ? (
          <PartsEmptyState onAddPart={handleTemplateSelect} existingParts={[]} />
        ) : (
          <div className="text-center py-12 bg-dark-200 rounded-lg">
            <p className="text-gray-400">No parts in inventory.</p>
            <p className="mt-2 text-sm text-gray-500">
              Type a part name above and press Enter to add it to inventory.
            </p>
          </div>
        )
      ) : (
        <>
          <div className="bg-dark-300 rounded-lg overflow-hidden">
            {/* Mobile View */}
            <div className="lg:hidden space-y-2 p-4">
              <div className="bg-primary-500/10 rounded-lg px-4 py-3 mb-4">
                <h3 className="text-sm font-semibold text-primary-500">Parts List</h3>
              </div>
              {parts.map(part => (
                <div 
                  key={part.id}
                  className="bg-dark-200 rounded-lg overflow-hidden hover:bg-dark-100 transition-colors"
                >
                  <div 
                    className="p-4 cursor-pointer"
                    onClick={() => setExpandedPartId(expandedPartId === part.id ? null : part.id)}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-white font-medium">{part.name}</h3>
                        <p className="text-sm text-gray-400 mt-1">{part.category}</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="text-sm text-primary-500">${part.cost.toLocaleString()}</p>
                        <ChevronDown 
                          className={`w-4 h-4 text-gray-400 transition-transform ${
                            expandedPartId === part.id ? 'transform rotate-180' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {expandedPartId === part.id && (
                    <div className="border-t border-dark-100 p-4 bg-dark-300">
                      <div className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Type
                          </label>
                          <select
                            value={part.category}
                            onChange={(e) => {
                              handleUpdatePart(part.id, {
                                category: e.target.value as PartCategory
                              });
                            }}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                          >
                            {PART_CATEGORIES.map(category => (
                              <option key={category} value={category}>{category}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Cost ($)
                          </label>
                          <input
                            type="number"
                            value={part.cost}
                            onChange={(e) => {
                              handleUpdatePart(part.id, {
                                cost: Number(e.target.value)
                              });
                            }}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Quantity
                          </label>
                          <input
                            type="number"
                            value={part.quantity}
                            min="0"
                            onChange={(e) => {
                              handleUpdatePart(part.id, {
                                quantity: Number(e.target.value)
                              });
                            }}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Condition
                          </label>
                          <select
                            value={part.condition}
                            onChange={(e) => {
                              handleUpdatePart(part.id, {
                                condition: e.target.value as typeof PART_CONDITIONS[number]
                              });
                            }}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                          >
                            {PART_CONDITIONS.map(condition => (
                              <option key={condition} value={condition}>{condition}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Notes
                          </label>
                          <textarea
                            value={part.notes || ''}
                            onChange={(e) => {
                              handleUpdatePart(part.id, {
                                notes: e.target.value
                              });
                            }}
                            rows={2}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Desktop View */}
            <table className="w-full hidden lg:table">
              <thead>
                <tr className="bg-primary-500/10">
                  <th className="px-6 py-4 text-left text-sm font-semibold text-primary-500">Part</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-primary-500">Category</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-primary-500">Cost</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-primary-500">Quantity</th>
                  <th className="px-6 py-4 text-right text-sm font-semibold text-primary-500">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-dark-200">
                {parts.map(part => (
                  <React.Fragment key={part.id}>
                    <tr 
                      className="group bg-dark-300 hover:bg-dark-100 transition-colors cursor-pointer"
                      onClick={() => setExpandedPartId(expandedPartId === part.id ? null : part.id)}
                    >
                      <td className="px-6 py-4">
                        <div>
                          <p className="text-white font-medium">{part.name}</p>
                          {part.manufacturer && (
                            <p className="text-sm text-gray-400">{part.manufacturer}</p>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-dark-50 text-primary-500">
                          {part.category}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-white">${part.cost.toLocaleString()}</td>
                      <td className="px-6 py-4 text-white">{part.quantity}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center justify-end gap-2">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeletePart(part.id);
                            }}
                            className="p-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                          <ChevronDown 
                            className={`w-4 h-4 text-primary-500 transition-transform ${
                              expandedPartId === part.id ? 'transform rotate-180' : ''
                            }`}
                          />
                        </div>
                      </td>
                    </tr>
                    {expandedPartId === part.id && (
                      <tr>
                        <td colSpan={5} className="px-6 py-4 bg-dark-200">
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">
                                Name
                              </label>
                              <input
                                type="text"
                                value={part.name}
                                onChange={(e) => {
                                  handleUpdatePart(part.id, {
                                    name: e.target.value
                                  });
                                }}
                                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">
                                Type
                              </label>
                              <select
                                value={part.category}
                                onChange={(e) => {
                                  handleUpdatePart(part.id, {
                                    category: e.target.value as PartCategory
                                  });
                                }}
                                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                              >
                                {PART_CATEGORIES.map(category => (
                                  <option key={category} value={category}>{category}</option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">
                                Cost ($)
                              </label>
                              <input
                                type="number"
                                value={part.cost}
                                onChange={(e) => {
                                  handleUpdatePart(part.id, {
                                    cost: Number(e.target.value)
                                  });
                                }}
                                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">
                                Quantity
                              </label>
                              <input
                                type="number"
                                value={part.quantity}
                                min="0"
                                onChange={(e) => {
                                  handleUpdatePart(part.id, {
                                    quantity: Number(e.target.value)
                                  });
                                }}
                                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">
                                Condition
                              </label>
                              <select
                                value={part.condition}
                                onChange={(e) => {
                                  handleUpdatePart(part.id, {
                                    condition: e.target.value as typeof PART_CONDITIONS[number]
                                  });
                                }}
                                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                              >
                                {PART_CONDITIONS.map(condition => (
                                  <option key={condition} value={condition}>{condition}</option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-400 mb-1">
                                Notes
                              </label>
                              <textarea
                                value={part.notes || ''}
                                onChange={(e) => {
                                  handleUpdatePart(part.id, {
                                    notes: e.target.value
                                  });
                                }}
                                rows={2}
                                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          
          {mode === 'installed' && (
            <PartsEmptyState onAddPart={handleTemplateSelect} existingParts={parts} />
          )}
        </>
      )}
    </div>
  );
} 