import React from 'react';

export default function LoadingScreen() {
  return (
    <div className="min-h-screen bg-dark-300 flex items-center justify-center">
      <div className="text-center">
        {/* Tachometer Container */}
        <div className="relative w-48 h-48">
          {/* Tachometer Background */}
          <div className="absolute inset-0 rounded-full border-8 border-dark-200" />
          
          {/* RPM Numbers */}
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className="absolute w-full h-full text-gray-400 text-sm"
              style={{
                transform: `rotate(${i * 30 - 120}deg)`,
              }}
            >
              <span
                className="absolute"
                style={{
                  left: '50%',
                  top: '10%',
                  transform: `translate(-50%, 0) rotate(${120 - i * 30}deg)`,
                }}
              >
                {i + 1}
              </span>
            </div>
          ))}

          {/* Ticks */}
          {[...Array(24)].map((_, i) => (
            <div
              key={i}
              className="absolute w-full h-full"
              style={{
                transform: `rotate(${i * 10 - 120}deg)`,
              }}
            >
              <div
                className={`absolute left-1/2 w-0.5 h-2 -translate-x-1/2 ${
                  i % 3 === 0 ? 'bg-white h-3' : 'bg-gray-600'
                }`}
                style={{ top: '4%' }}
              />
            </div>
          ))}

          {/* Redline Arc */}
          <div
            className="absolute w-full h-full overflow-hidden"
            style={{
              clipPath: 'polygon(50% 0, 100% 0, 100% 50%, 50% 50%)',
              transform: 'rotate(60deg)',
            }}
          >
            <div className="absolute inset-0 border-[16px] border-transparent border-t-red-500/20 rounded-full" />
          </div>

          {/* Animated Needle */}
          <div
            className="absolute w-full h-full animate-[tachometer_2s_cubic-bezier(0.4,0,0.6,1)_infinite]"
            style={{
              transformOrigin: 'center center',
              transform: 'rotate(-120deg)',
            }}
          >
            <div className="absolute left-1/2 -translate-x-1/2 w-1 h-24 bg-primary-500 origin-bottom" />
          </div>

          {/* Center Cap */}
          <div className="absolute inset-0 m-auto w-8 h-8 rounded-full bg-dark-200 border-4 border-primary-500" />
        </div>

        <h2 className="mt-8 text-xl font-semibold text-white">Loading...</h2>
      </div>

      <style>{`
        @keyframes tachometer {
          0% { transform: rotate(-120deg); }
          50% { transform: rotate(90deg); }
          100% { transform: rotate(-120deg); }
        }
      `}</style>
    </div>
  );
}