import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { toast } from 'react-hot-toast';
import type { Vehicle } from '../types/vehicle';
import VehicleFormComponent from '../components/vehicles/VehicleForm';

export default function VehicleForm() {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formData: Partial<Vehicle>) => {
    if (!programId) {
      toast.error('No program ID found. Please try logging out and back in.');
      setError('No program ID found. Please try logging out and back in.');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      // Create new vehicle
      const vehiclesRef = collection(db, 'programs', programId, 'vehicles');
      await addDoc(vehiclesRef, {
        ...formData,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      toast.success('Vehicle added successfully!');
      navigate('/garage');
    } catch (err) {
      console.error('Error saving vehicle:', err);
      toast.error('Failed to save vehicle');
      setError('Failed to save vehicle');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
        <button
          onClick={() => navigate('/garage')}
          className="mt-4 text-primary-500 hover:text-primary-400"
        >
          Return to Garage
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <VehicleFormComponent 
        onSubmit={handleSubmit} 
        isSubmitting={isSubmitting} 
      />
    </div>
  );
} 