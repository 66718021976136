import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Calendar, ChevronRight, Clock, MapPin, Car } from 'lucide-react';
import { MonthView } from '../components/calendar/MonthView';
import { WeekView } from '../components/calendar/WeekView';
import { DayView } from '../components/calendar/DayView';
import { YearView } from '../components/calendar/YearView';
import { CalendarHeader } from '../components/calendar/CalendarHeader';
import { SeasonTimeline } from '../components/calendar/SeasonTimeline';
import { useAuth } from '../contexts/AuthContext';
import type { TrackEvent, EventStatus } from '../types/event';
import { format, isSameDay, isSameYear } from 'date-fns';

interface ScheduleProps {
  events: TrackEvent[];
  onUpdateEvent: (event: TrackEvent) => void;
}

type ViewType = 'month' | 'week' | 'day' | 'year';

export default function Schedule({ events, onUpdateEvent }: ScheduleProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState<ViewType>('month');
  const [isCalendarCollapsed, setIsCalendarCollapsed] = useState(false);

  // Group events by status
  const plannedEvents = events.filter(e => e.status === 'planned');
  const confirmedEvents = events.filter(e => e.status === 'confirmed');
  const completedEvents = events.filter(e => e.status === 'completed');

  // Filter events for current year
  const currentYearEvents = events.filter(event => 
    isSameYear(new Date(event.startDate), new Date())
  );

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Season Planning</h1>
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate('/schedule/new', { 
              state: { 
                isNew: true,
                date: new Date()
              }
            })}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Event
          </button>
          <button
            onClick={() => navigate('/schedule/plan-season')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
          >
            <Calendar className="w-4 h-4" />
            Plan Your Season
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className={isCalendarCollapsed ? "lg:col-span-2" : "lg:col-span-3"}>
          {/* Season Overview */}
          <div className="bg-dark-200 rounded-lg p-6 mb-6">
            <h2 className="text-lg font-semibold text-white mb-4">Season Overview</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="bg-dark-100 rounded-lg p-4">
                <p className="text-sm text-gray-400">Total Events</p>
                <p className="text-2xl font-semibold text-white">{events.length}</p>
              </div>
              <div className="bg-dark-100 rounded-lg p-4">
                <p className="text-sm text-gray-400">Planned</p>
                <p className="text-2xl font-semibold text-white">{plannedEvents.length}</p>
              </div>
              <div className="bg-dark-100 rounded-lg p-4">
                <p className="text-sm text-gray-400">Confirmed</p>
                <p className="text-2xl font-semibold text-white">{confirmedEvents.length}</p>
              </div>
              <div className="bg-dark-100 rounded-lg p-4">
                <p className="text-sm text-gray-400">Completed</p>
                <p className="text-2xl font-semibold text-white">{completedEvents.length}</p>
              </div>
            </div>
            {events.length < 4 && (
              <div className="mt-6 p-6 bg-dark-100 rounded-lg border border-primary-500/20">
                <div className="flex items-start gap-4">
                  <div className="p-3 bg-primary-500/10 rounded-lg">
                    <Calendar className="w-6 h-6 text-primary-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-2">Plan Your Racing Season</h3>
                    <p className="text-gray-400 mb-4">
                      Get started by planning out your entire racing season. Add multiple events at once, including races, practice sessions, and testing days.
                    </p>
                    <button
                      onClick={() => navigate('/schedule/plan-season')}
                      className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors"
                    >
                      <Calendar className="w-5 h-5" />
                      Plan Your Season Now
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Season Timeline - only show if there are more than 3 events in the current year */}
          {currentYearEvents.length > 3 && (
            <SeasonTimeline events={currentYearEvents} />
          )}

          {/* Calendar and Events Grid Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Calendar Section */}
            {!isCalendarCollapsed && (
              <div className="bg-dark-200 rounded-lg">
                <div className="flex items-center justify-between p-4 border-b border-dark-50">
                  <div className="flex-1">
                    <CalendarHeader
                      currentDate={currentDate}
                      onDateChange={setCurrentDate}
                      view={view}
                      onViewChange={setView}
                    />
                  </div>
                  <button
                    onClick={() => setIsCalendarCollapsed(true)}
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1 ml-4"
                  >
                    Collapse
                    <Calendar className="w-4 h-4" />
                  </button>
                </div>
                <div className="p-4">
                  {view === 'month' && (
                    <MonthView
                      currentDate={currentDate}
                      events={events}
                      onSelectDate={(date) => navigate('/schedule/new', { 
                        state: { 
                          isNew: true,
                          date 
                        }
                      })}
                      onSelectEvent={(event) => navigate(`/schedule/${event.id}`)}
                    />
                  )}
                  {view === 'week' && (
                    <WeekView
                      currentDate={currentDate}
                      events={events}
                      onSelectDate={(date) => navigate('/schedule/new', { 
                        state: { 
                          isNew: true,
                          date 
                        }
                      })}
                      onSelectEvent={(event) => navigate(`/schedule/${event.id}`)}
                    />
                  )}
                  {view === 'day' && (
                    <DayView
                      currentDate={currentDate}
                      events={events}
                      onSelectDate={(date) => navigate('/schedule/new', { 
                        state: { 
                          isNew: true,
                          date 
                        }
                      })}
                      onSelectEvent={(event) => navigate(`/schedule/${event.id}`)}
                    />
                  )}
                  {view === 'year' && (
                    <YearView
                      currentDate={currentDate}
                      events={events}
                      onSelectDate={(date) => navigate('/schedule/new', { 
                        state: { 
                          isNew: true,
                          date 
                        }
                      })}
                      onSelectEvent={(event) => navigate(`/schedule/${event.id}`)}
                    />
                  )}
                </div>
              </div>
            )}

            {/* Events List */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-white">Events</h2>
                <button
                  onClick={() => navigate('/schedule/new', { 
                    state: { 
                      isNew: true,
                      date: new Date()
                    }
                  })}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  Add Event
                  <Plus className="w-4 h-4" />
                </button>
              </div>
              <div className="space-y-3">
                {events.length === 0 ? (
                  <p className="text-center text-gray-400 py-4">No events planned yet.</p>
                ) : (
                  events.map(event => (
                    <button
                      key={event.id}
                      onClick={() => navigate(`/schedule/${event.id}`)}
                      className="w-full flex items-center justify-between p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                    >
                      <div className="flex items-center gap-3">
                        <div className="p-2 bg-primary-500/10 rounded-lg">
                          {event.type === 'race' ? (
                            <Calendar className="w-5 h-5 text-primary-500" />
                          ) : event.type === 'practice' ? (
                            <Clock className="w-5 h-5 text-primary-500" />
                          ) : (
                            <Calendar className="w-5 h-5 text-primary-500" />
                          )}
                        </div>
                        <div className="text-left">
                          <h3 className="text-white font-medium group-hover:text-primary-500">
                            {event.title}
                          </h3>
                          <div className="flex items-center gap-4 text-sm text-gray-400">
                            <div className="flex items-center gap-1">
                              <Calendar className="w-4 h-4" />
                              {format(event.startDate, 'MMM d, yyyy')}
                              {!isSameDay(event.startDate, event.endDate) && (
                                <>
                                  <span className="mx-1">→</span>
                                  {format(event.endDate, 'MMM d, yyyy')}
                                </>
                              )}
                            </div>
                            {event.location && (
                              <div className="flex items-center gap-1">
                                <MapPin className="w-4 h-4" />
                                {typeof event.location === 'string' 
                                  ? event.location 
                                  : event.location.name || event.location.address
                                }
                              </div>
                            )}
                            {event.vehicleId && (
                              <div className="flex items-center gap-1">
                                <Car className="w-4 h-4" />
                                Vehicle
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <ChevronRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                    </button>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar - if calendar is collapsed */}
        {isCalendarCollapsed && (
          <div className="lg:col-span-1">
            <div className="bg-dark-200 rounded-lg p-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-white">Mini Calendar</h3>
                <button
                  onClick={() => setIsCalendarCollapsed(false)}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  Expand
                  <Calendar className="w-4 h-4" />
                </button>
              </div>
              <MonthView
                currentDate={currentDate}
                events={events}
                onSelectDate={(date) => navigate('/schedule/new', { 
                  state: { 
                    isNew: true,
                    date 
                  }
                })}
                onSelectEvent={(event) => navigate(`/schedule/${event.id}`)}
                mini={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}