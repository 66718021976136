import React from 'react';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isSameMonth,
  isSameDay,
  startOfDay,
  endOfDay,
  isWithinInterval,
} from 'date-fns';
import type { TrackEvent } from '../../types/event';

interface MonthViewProps {
  currentDate: Date;
  events: TrackEvent[];
  onSelectDate: (date: Date) => void;
  onSelectEvent: (event: TrackEvent) => void;
  mini?: boolean;
}

export function MonthView({ currentDate, events, onSelectDate, onSelectEvent, mini = false }: MonthViewProps) {
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const calendarStart = startOfWeek(monthStart);
  const calendarEnd = endOfWeek(monthEnd);

  const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

  return (
    <div className={`grid grid-cols-7 ${mini ? 'gap-0' : 'gap-1'}`}>
      {/* Day headers */}
      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
        <div 
          key={day} 
          className={`text-center text-gray-400 font-medium
            ${mini ? 'text-xs py-1' : 'p-2'}
          `}
        >
          {day}
        </div>
      ))}

      {/* Calendar days */}
      {days.map((day, index) => {
        const isToday = isSameDay(day, new Date());
        const dayEvents = events.filter(event => 
          isWithinInterval(day, {
            start: startOfDay(event.startDate),
            end: endOfDay(event.endDate)
          })
        );
        const isCurrentMonth = isSameMonth(day, currentDate);
        const hasEvents = dayEvents.length > 0;

        return (
          <button
            key={index}
            onClick={() => onSelectDate(day)}
            className={`
              relative flex flex-col items-center justify-center
              ${mini ? 'p-1 h-8 hover:bg-transparent' : 'p-2 h-24 hover:bg-dark-50'}
              ${isCurrentMonth ? 'text-white' : 'text-gray-600'}
              transition-colors group
            `}
          >
            <div className="flex flex-col items-center">
              <div className={`
                relative z-10 flex items-center justify-center
                ${mini ? 'w-6 h-6' : ''}
                ${isToday ? 'text-primary-500 font-bold' : ''}
                ${mini ? 'group-hover:bg-dark-50 rounded-full transition-colors' : ''}
                ${isToday && mini ? 'bg-primary-500/10' : ''}
              `}>
                {format(day, 'd')}
              </div>

              {/* Event indicator */}
              {mini && hasEvents && (
                <div className="w-1.5 h-1.5 rounded-full bg-primary-500 mt-0.5" />
              )}
            </div>

            {/* Full event list (only for non-mini view) */}
            {!mini && (
              <div className="mt-1 w-full space-y-1">
                {dayEvents.map((event, eventIndex) => (
                  <div
                    key={eventIndex}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectEvent(event);
                    }}
                    className={`
                      text-xs p-1 rounded truncate cursor-pointer
                      ${event.type === 'race' ? 'bg-red-500/20 text-red-400' : 
                        event.type === 'practice' ? 'bg-blue-500/20 text-blue-400' :
                        event.type === 'maintenance' ? 'bg-yellow-500/20 text-yellow-400' :
                        'bg-gray-500/20 text-gray-400'}
                    `}
                  >
                    {event.title}
                  </div>
                ))}
              </div>
            )}
          </button>
        );
      })}
    </div>
  );
}