import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, Loader2 } from 'lucide-react';
import { collection, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import VehicleCard from '../components/vehicles/VehicleCard';
import type { Vehicle } from '../types/vehicle';
import { toast } from 'react-hot-toast';

interface GarageProps {
  vehicles: Vehicle[];
  setVehicles: Dispatch<SetStateAction<Vehicle[]>>;
}

export default function Garage({ vehicles, setVehicles }: GarageProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Just set loading to false since we're getting vehicles from props
    setIsLoading(false);
  }, []);

  const handleEdit = (vehicleId: string) => {
    navigate(`/garage/vehicles/${vehicleId}/edit`);
  };

  const handleVehicleClick = (vehicleId: string) => {
    navigate(`/garage/vehicles/${vehicleId}`);
  };

  const handleDeleteVehicle = async (vehicleId: string) => {
    if (!programId) return;
    
    try {
      await deleteDoc(doc(db, 'programs', programId, 'vehicles', vehicleId));
    } catch (error) {
      console.error('Error deleting vehicle:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  return (
    <div className="space-y-12">
      {/* Vehicles Section */}
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-white">Vehicles</h2>
          <Link
            to="/garage/vehicles/new"
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-5 h-5" />
            Add Vehicle
          </Link>
        </div>

        {vehicles.length === 0 ? (
          <div className="text-center py-12 bg-dark-200 rounded-lg">
            <p className="text-gray-400">No vehicles added yet.</p>
            <Link
              to="/garage/vehicles/new"
              className="mt-4 inline-block text-primary-500 hover:text-primary-400"
            >
              Add your first vehicle
            </Link>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {vehicles.map(vehicle => (
              <VehicleCard
                key={vehicle.id}
                vehicle={vehicle}
                onClick={() => handleVehicleClick(vehicle.id)}
                onEdit={() => handleEdit(vehicle.id)}
                onDelete={() => handleDeleteVehicle(vehicle.id)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}