import React, { useState, useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import ProtectedRoute from './components/auth/ProtectedRoute';
import { collection, query, orderBy, onSnapshot, doc, setDoc, addDoc, getDoc } from 'firebase/firestore';
import { db } from './config/firebase';
import { useAuth } from './contexts/AuthContext';
import { Toaster } from './components';

// Pages
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
import SignupPage from './pages/auth/SignupPage';
import PaymentPage from './pages/auth/PaymentPage';
import Dashboard from './pages/Dashboard';
import Garage from './pages/Garage';
import VehicleDetail from './pages/VehicleDetail';
import VehicleForm from './pages/VehicleForm';
import Schedule from './pages/Schedule';
import EventDetail from './pages/EventDetail';
import PlanSeason from './pages/PlanSeason';
import Budget from './pages/Budget';
import Tasks from './pages/Tasks';
import TaskDetail from './pages/TaskDetail';
import Checklists from './pages/Checklists';
import ChecklistDetail from './pages/ChecklistDetail';
import Maintenance from './pages/Maintenance';
import Account from './pages/Account';
import Subscription from './pages/Subscription';
import Settings from './pages/Settings';

// Types
import type { Vehicle } from './types/vehicle';
import type { Task } from './types/task';
import type { TrackEvent } from './types/event';
import type { Checklist } from './types/checklist';
import type { Budget as BudgetType } from './types/budget';

function AppContent() {
  const { programId } = useAuth();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [events, setEvents] = useState<TrackEvent[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [budget, setBudget] = useState<BudgetType | null>(null);

  // Fetch vehicles from Firestore
  useEffect(() => {
    if (!programId) return;

    const vehiclesRef = collection(db, 'programs', programId, 'vehicles');
    const q = query(vehiclesRef, orderBy('make'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedVehicles = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Vehicle[];

      setVehicles(fetchedVehicles);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch budget from Firestore
  useEffect(() => {
    if (!programId) return;

    const budgetRef = doc(db, 'programs', programId, 'budget', 'current');
    
    const unsubscribe = onSnapshot(budgetRef, (snapshot) => {
      if (snapshot.exists()) {
        setBudget(snapshot.data() as BudgetType);
      } else {
        setBudget(null);
      }
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch events from Firestore
  useEffect(() => {
    if (!programId) return;

    const eventsRef = collection(db, 'programs', programId, 'events');
    const q = query(eventsRef, orderBy('startDate'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedEvents = snapshot.docs.map(doc => {
        const data = doc.data();
        const eventDate = data.date?.toDate();
        // Ensure all required fields are present with defaults
        return {
          id: doc.id,
          title: data.title || '',
          type: data.type || 'race',
          status: data.status || 'planned',
          description: data.description,
          startDate: eventDate || data.startDate?.toDate() || new Date(),
          endDate: eventDate || data.endDate?.toDate() || new Date(),
          location: data.location,
          vehicleId: data.vehicleId,
          budget: data.budget,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt
        } as TrackEvent;
      });

      setEvents(fetchedEvents);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch tasks from Firestore
  useEffect(() => {
    if (!programId) return;

    const tasksRef = collection(db, 'programs', programId, 'tasks');
    const q = query(tasksRef, orderBy('dueDate'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedTasks = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dueDate: data.dueDate ? data.dueDate.toDate?.() || data.dueDate : null,
        };
      }) as Task[];

      setTasks(fetchedTasks);
    });

    return () => unsubscribe();
  }, [programId]);

  // Fetch checklists from Firestore
  useEffect(() => {
    if (!programId) return;

    const checklistsRef = collection(db, 'programs', programId, 'checklists');
    const q = query(checklistsRef, orderBy('name'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedChecklists = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Checklist[];

      setChecklists(fetchedChecklists);
    });

    return () => unsubscribe();
  }, [programId]);

  const handleUpdateVehicle = async (vehicle: Vehicle) => {
    if (!programId) return;

    try {
      if (!vehicle.id) {
        // Create new vehicle
        const vehiclesRef = collection(db, 'programs', programId, 'vehicles');
        await addDoc(vehiclesRef, {
          ...vehicle,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      } else {
        // Update existing vehicle
        const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicle.id);
        await setDoc(vehicleRef, {
          ...vehicle,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating vehicle:', error);
    }
  };

  const handleUpdateEvent = async (event: TrackEvent) => {
    if (!programId) return;

    try {
      if (!event.id) {
        // Create new event
        const eventsRef = collection(db, 'programs', programId, 'events');
        await addDoc(eventsRef, {
          ...event,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      } else {
        // Update existing event
        const eventRef = doc(db, 'programs', programId, 'events', event.id);
        await setDoc(eventRef, {
          ...event,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleUpdateTask = async (task: Task) => {
    if (!programId) return;

    try {
      if (!task.id) {
        // Create new task
        const tasksRef = collection(db, 'programs', programId, 'tasks');
        await addDoc(tasksRef, {
          ...task,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      } else {
        // Update existing task
        const taskRef = doc(db, 'programs', programId, 'tasks', task.id);
        await setDoc(taskRef, {
          ...task,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleUpdateChecklist = async (checklist: Checklist) => {
    if (!programId) return;

    try {
      if (!checklist.id) {
        // Create new checklist
        const checklistsRef = collection(db, 'programs', programId, 'checklists');
        await addDoc(checklistsRef, {
          ...checklist,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      } else {
        // Update existing checklist
        const checklistRef = doc(db, 'programs', programId, 'checklists', checklist.id);
        await setDoc(checklistRef, {
          ...checklist,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error updating checklist:', error);
    }
  };

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/payment" element={<PaymentPage />} />

      {/* Protected routes */}
      <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
        <Route path="/" element={<Dashboard events={events} vehicles={vehicles} tasks={tasks} budget={budget} />} />
        <Route path="/schedule" element={<Schedule events={events} onUpdateEvent={handleUpdateEvent} />} />
        <Route path="/schedule/plan-season" element={<PlanSeason />} />
        <Route path="/schedule/new" element={<EventDetail events={events} onUpdateEvent={handleUpdateEvent} />} />
        <Route path="/schedule/:id" element={<EventDetail events={events} onUpdateEvent={handleUpdateEvent} />} />
        <Route 
          path="/garage" 
          element={<Garage vehicles={vehicles} setVehicles={setVehicles} />} 
        />
        <Route 
          path="/garage/vehicles/new" 
          element={<VehicleForm />} 
        />
        <Route 
          path="/garage/vehicles/:id" 
          element={<VehicleDetail vehicles={vehicles} onUpdateVehicle={handleUpdateVehicle} />} 
        />
        <Route 
          path="/budget" 
          element={<Budget budget={budget} setBudget={setBudget} />} 
        />
        <Route 
          path="/tasks" 
          element={<Tasks tasks={tasks} setTasks={setTasks} />} 
        />
        <Route 
          path="/tasks/:id" 
          element={<TaskDetail tasks={tasks} onUpdateTask={handleUpdateTask} />} 
        />
        <Route 
          path="/tasks/new" 
          element={<TaskDetail tasks={tasks} onUpdateTask={handleUpdateTask} />} 
        />
        <Route 
          path="/checklists" 
          element={<Checklists checklists={checklists} setChecklists={setChecklists} />} 
        />
        <Route 
          path="/checklists/:id" 
          element={<ChecklistDetail checklists={checklists} onUpdateChecklist={handleUpdateChecklist} />} 
        />
        <Route 
          path="/checklists/new" 
          element={<ChecklistDetail checklists={checklists} onUpdateChecklist={handleUpdateChecklist} />} 
        />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/account" element={<Account />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/settings" element={<Settings />} />
      </Route>

      {/* Redirect root to dashboard */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
        <Toaster />
      </AuthProvider>
    </Router>
  );
}