import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Edit, ArrowLeft, Plus, Trash2, Loader2, ChevronDown } from 'lucide-react';
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc, setDoc, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Vehicle, VehicleSetup } from '../types/vehicle';
import VehicleSetupComponent from '../components/vehicles/setup/VehicleSetup';
import VehicleModifications from '../components/vehicles/modifications/VehicleModifications';
import type { Part } from '../types/part';
import { getSetupSummary } from '../components/vehicles/setup/VehicleSetup';
import ConsumablesStatus from '../components/vehicles/consumables/ConsumablesStatus';
import RecommendedMaintenance from '../components/vehicles/consumables/RecommendedMaintenance';
import PartsTable from '../components/parts/PartsTable';

const createInitialSetup = () => ({
  id: 'default',
  setupName: 'Default Setup',
  setupDescription: null,
  alignment: {
    frontCamber: 0,
    rearCamber: 0,
    frontToe: 0,
    rearToe: 0,
    caster: 0
  },
  suspension: {
    frontRideHeight: 0,
    rearRideHeight: 0,
    frontSpringRate: 0,
    rearSpringRate: 0,
    frontRebound: 0,
    rearRebound: 0,
    frontCompression: 0,
    rearCompression: 0,
    frontBarSetting: null,
    rearBarSetting: null
  },
  aero: {
    frontDownforce: 0,
    rearDownforce: 0,
    wingAngle: 0,
    splitterHeight: 0,
    rideHeight: 0
  },
  tires: {
    compound: 'Unknown',
    frontPressureCold: 0,
    rearPressureCold: 0,
    frontPressureHot: 0,
    rearPressureHot: 0,
    frontCamber: 0,
    rearCamber: 0,
    frontToe: 0,
    rearToe: 0
  },
  brakes: {
    brakeBias: 50,
    frontPressure: 0,
    rearPressure: 0,
    padType: 'Unknown'
  },
  differential: {
    frontPreload: null,
    rearPreload: null,
    centerPreload: null,
    frontRampAngle: null,
    rearRampAngle: null,
    lockingPercentage: null
  },
  gearing: {
    finalDrive: 0,
    gearRatios: []
  },
  weight: {
    cornerWeights: {
      frontLeft: 0,
      frontRight: 0,
      rearLeft: 0,
      rearRight: 0
    },
    ballast: 0,
    ballastPosition: 'N/A',
    totalWeight: 0,
    distribution: {
      frontToRear: 50,
      leftToRight: 50
    }
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
});

const mockConsumables = [
  {
    type: 'Brake Pads',
    wearPercentage: 30,
    expectedReplacement: '2024-06-15',
    brand: 'Ferodo DS2500',
    installedAt: '2024-01-15'
  },
  {
    type: 'Tires',
    wearPercentage: 45,
    expectedReplacement: '2024-05-20',
    brand: 'Michelin PS4S',
    installedAt: '2023-11-01'
  },
  {
    type: 'Engine Oil',
    wearPercentage: 60,
    expectedReplacement: '2024-04-01',
    brand: 'Motul 300V',
    installedAt: '2023-12-15'
  },
  {
    type: 'Brake Fluid',
    wearPercentage: 20,
    expectedReplacement: '2024-08-01',
    brand: 'Motul RBF 660',
    installedAt: '2023-08-01'
  }
];

interface VehicleDetailProps {
  vehicles: Vehicle[];
  onUpdateVehicle: (vehicle: Vehicle) => Promise<void>;
}

export default function VehicleDetail({ vehicles, onUpdateVehicle }: VehicleDetailProps) {
  const { id: routeId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [vehicleParts, setVehicleParts] = useState<Part[]>([]);
  const [isSetupExpanded, setIsSetupExpanded] = useState(false);

  const vehicleId = routeId;

  useEffect(() => {
    async function fetchVehicle() {
      if (!programId || !vehicleId) return;

      try {
        const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
        const setupRef = doc(db, 'programs', programId, 'vehicles', vehicleId, 'setups', 'default');
        
        const [vehicleSnap, setupSnap] = await Promise.all([
          getDoc(vehicleRef),
          getDoc(setupRef)
        ]);
        
        if (vehicleSnap.exists()) {
          let setupData;
          
          if (!setupSnap.exists()) {
            // Create initial setup if it doesn't exist
            const initialSetup = createInitialSetup();
            await setDoc(setupRef, initialSetup);
            setupData = initialSetup;
          } else {
            setupData = setupSnap.data();
          }
          
          const vehicleData = { 
            id: vehicleSnap.id, 
            ...vehicleSnap.data(),
            setup: setupData
          } as Vehicle;
          
          setVehicle(vehicleData);
        } else {
          setError('Vehicle not found');
        }
      } catch (err) {
        console.error('Error fetching vehicle:', err);
        setError('Failed to load vehicle');
      } finally {
        setIsLoading(false);
      }
    }

    fetchVehicle();
  }, [programId, vehicleId]);

  useEffect(() => {
    if (!programId || !vehicleId) return;

    const partsRef = collection(db, 'programs', programId, 'parts');
    const q = query(partsRef, where('vehicleId', '==', vehicleId));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const parts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Part[];
      setVehicleParts(parts);
    });

    return () => unsubscribe();
  }, [programId, vehicleId]);

  const handleUpdateVehicle = async (updatedVehicle: Vehicle) => {
    if (!programId || !vehicleId) return;

    try {
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
      await updateDoc(vehicleRef, {
        ...updatedVehicle,
        updatedAt: new Date().toISOString()
      });
      setVehicle(updatedVehicle);
    } catch (err) {
      console.error('Error updating vehicle:', err);
      setError('Failed to update vehicle');
    }
  };

  const handleUpdateSetup = async (updatedSetup: VehicleSetup) => {
    if (!vehicle || !programId || !vehicleId) return;

    try {
      // Reference to the default setup document
      const setupRef = doc(db, 'programs', programId, 'vehicles', vehicleId, 'setups', 'default');
      
      // Update the setup in Firestore
      await setDoc(setupRef, {
        ...updatedSetup,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      setVehicle(prev => prev ? {
        ...prev,
        setup: updatedSetup
      } : null);
    } catch (err) {
      console.error('Error updating setup:', err);
      setError('Failed to update setup');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  if (error || !vehicle) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-400">{error || 'Vehicle not found.'}</p>
        <button
          onClick={() => navigate('/garage')}
          className="mt-4 text-primary-500 hover:text-primary-400"
        >
          Return to Garage
        </button>
      </div>
    );
  }

  const calculatePowerToWeight = () => {
    if (!vehicle) return '0.00';
    return (vehicle.weight / vehicle.power).toFixed(2);
  };

  const totalSpent = vehicleParts.reduce((sum, part) => sum + (part.cost || 0), 0);

  const setupSummary = getSetupSummary(vehicle?.setup);
  const partsCount = vehicleParts.length;

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center gap-4">
        <button
          onClick={() => navigate('/garage')}
          className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
        >
          <ArrowLeft className="w-5 h-5" />
        </button>
        <h1 className="text-2xl font-bold text-white">
          {vehicle.name || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
        </h1>
      </div>

      {/* Vehicle Summary */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-semibold text-white">Vehicle Summary</h2>
            {vehicle.name && (
              <p className="text-gray-400">{vehicle.year} {vehicle.make} {vehicle.model}</p>
            )}
          </div>
          <button
            onClick={() => navigate(`/garage/vehicles/${vehicleId}/edit`)}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <Edit className="w-5 h-5" />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div>
            <p className="text-gray-400">Power Output</p>
            <p className="text-xl font-semibold text-white">
              {vehicle.power} hp
            </p>
          </div>
          <div>
            <p className="text-gray-400">Weight</p>
            <p className="text-xl font-semibold text-white">
              {vehicle.weight} lbs
            </p>
          </div>
          <div>
            <p className="text-gray-400">Power-to-Weight</p>
            <p className="text-xl font-semibold text-white">
              {calculatePowerToWeight()} lb/hp
            </p>
          </div>
          <div>
            <p className="text-gray-400">Total Invested</p>
            <p className="text-xl font-semibold text-white">
              ${totalSpent.toLocaleString()}
            </p>
          </div>
        </div>

        <div className="mt-6 pt-6 border-t border-dark-100">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div>
              <p className="text-gray-400">Current Setup</p>
              {setupSummary ? (
                <div className="space-y-1">
                  <p className="text-sm text-white">{setupSummary.alignment}</p>
                  <p className="text-sm text-white">{setupSummary.tires.compound}</p>
                  <p className="text-sm text-white">{setupSummary.tires.pressures}</p>
                </div>
              ) : (
                <p className="text-sm text-gray-500">No setup configured</p>
              )}
            </div>
            <div>
              <p className="text-gray-400">Installed Parts</p>
              <p className="text-xl font-semibold text-white">
                {partsCount} {partsCount === 1 ? 'part' : 'parts'}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 pt-6 border-t border-dark-100">
          <h3 className="text-lg font-medium text-white mb-4">Consumables Status</h3>
          <ConsumablesStatus consumables={mockConsumables} />
        </div>

        <div className="mt-6 pt-6 border-t border-dark-100">
          <RecommendedMaintenance />
        </div>
      </div>

      {/* Vehicle Setup Section */}
      <div className="bg-dark-200 rounded-lg overflow-hidden">
        <div 
          className="flex justify-between items-center cursor-pointer p-6 hover:bg-dark-100/50 transition-colors border-b border-dark-100"
          onClick={() => setIsSetupExpanded(!isSetupExpanded)}
        >
          <div className="flex items-center gap-3">
            <h2 className="text-xl font-semibold text-white">Vehicle Setup</h2>
            <span className="text-sm px-2 py-1 rounded-full bg-dark-300 text-gray-400">
              {isSetupExpanded ? 'Click to collapse' : 'Click to expand'}
            </span>
          </div>
          <ChevronDown 
            className={`w-6 h-6 text-primary-500 transition-transform duration-200 ${
              isSetupExpanded ? 'transform rotate-180' : ''
            }`}
          />
        </div>

        {/* Setup Summary (always visible) */}
        <div className="p-6 bg-dark-200">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Alignment Card */}
            <div className="bg-dark-100/50 rounded-lg p-4 hover:bg-dark-100 transition-colors">
              <div className="flex items-center gap-2 mb-3">
                <div className="w-8 h-8 rounded-full bg-primary-500/10 flex items-center justify-center">
                  <span className="text-primary-500 text-lg font-semibold">A</span>
                </div>
                <h3 className="text-gray-400 font-medium">Alignment</h3>
              </div>
              <div className="space-y-1">
                <p className="text-white text-sm">
                  Front Camber: {vehicle?.setup?.alignment?.frontCamber || 0}°
                </p>
                <p className="text-white text-sm">
                  Rear Camber: {vehicle?.setup?.alignment?.rearCamber || 0}°
                </p>
              </div>
            </div>

            {/* Tires Card */}
            <div className="bg-dark-100/50 rounded-lg p-4 hover:bg-dark-100 transition-colors">
              <div className="flex items-center gap-2 mb-3">
                <div className="w-8 h-8 rounded-full bg-primary-500/10 flex items-center justify-center">
                  <span className="text-primary-500 text-lg font-semibold">T</span>
                </div>
                <h3 className="text-gray-400 font-medium">Tires</h3>
              </div>
              <div className="space-y-1">
                <p className="text-white text-sm">
                  Compound: {vehicle?.setup?.tires?.compound || 'Not set'}
                </p>
                <p className="text-white text-sm">
                  Front Pressure: {vehicle?.setup?.tires?.frontPressureCold || 0} psi
                </p>
              </div>
            </div>

            {/* Brakes Card */}
            <div className="bg-dark-100/50 rounded-lg p-4 hover:bg-dark-100 transition-colors">
              <div className="flex items-center gap-2 mb-3">
                <div className="w-8 h-8 rounded-full bg-primary-500/10 flex items-center justify-center">
                  <span className="text-primary-500 text-lg font-semibold">B</span>
                </div>
                <h3 className="text-gray-400 font-medium">Brakes</h3>
              </div>
              <div className="space-y-1">
                <p className="text-white text-sm">
                  Bias: {vehicle?.setup?.brakes?.brakeBias || 50}% front
                </p>
                <p className="text-white text-sm">
                  Pad Type: {vehicle?.setup?.brakes?.padType || 'Not set'}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Full Setup Editor (expandable) */}
        <div 
          className={`transform transition-all duration-300 ease-in-out ${
            isSetupExpanded ? 'max-h-[5000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}
        >
          <div className="bg-dark-100 px-6 py-4 border-t border-b border-dark-100">
            <div className="flex items-center gap-2">
              <div className="w-1 h-6 bg-primary-500 rounded-full"></div>
              <h3 className="text-lg font-medium text-white">Detailed Setup Configuration</h3>
            </div>
          </div>
          <div className="bg-dark-100 p-6">
            <div className="bg-dark-200 rounded-lg p-6">
              <VehicleSetupComponent
                setup={vehicle?.setup}
                onUpdateSetup={handleUpdateSetup}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modifications Section */}
      <div className="bg-dark-200 rounded-lg p-6">
        <h2 className="text-xl font-semibold text-white mb-6">Installed Parts</h2>
        <PartsTable 
          parts={vehicleParts}
          vehicleId={vehicleId}
          vehicle={vehicle}
          mode="installed"
        />
      </div>
    </div>
  );
}