import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Car, Calendar, DollarSign, CheckSquare } from 'lucide-react';

export default function LandingPage() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return null;
  }

  if (user) {
    return <Navigate to="/" replace />;
  }

  const features = [
    {
      icon: Car,
      title: 'Vehicle Management',
      description: 'Track multiple vehicles, modifications, and maintenance schedules.'
    },
    {
      icon: Calendar,
      title: 'Event Planning',
      description: 'Organize race events, practice sessions, and maintenance schedules.'
    },
    {
      icon: DollarSign,
      title: 'Budget Tracking',
      description: 'Monitor expenses, sponsorships, and event costs.'
    },
    {
      icon: CheckSquare,
      title: 'Task Management',
      description: 'Stay on top of preparations with customizable checklists.'
    }
  ];

  return (
    <div className="min-h-screen bg-dark-300">
      <nav className="bg-dark-200 border-b border-dark-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <span className="text-xl font-bold text-primary-500">PitPrep</span>
            <div>
              <Link
                to="/login"
                className="bg-primary-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-primary-600"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">
            Motorsport Management Made Simple
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Track your vehicles, manage events, and stay on top of maintenance with our comprehensive motorsport management platform.
          </p>
          <div className="mt-8">
            <Link
              to="/login"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600"
            >
              Get Started
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature) => (
            <div
              key={feature.title}
              className="bg-dark-200 rounded-lg p-6 text-center"
            >
              <feature.icon className="w-12 h-12 text-primary-500 mx-auto mb-4" />
              <h3 className="text-lg font-semibold text-white mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}