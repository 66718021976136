import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Consumable {
  type: string;
  wearPercentage: number;
  expectedReplacement: string;
  brand?: string;
  installedAt: string;
}

interface ConsumablesStatusProps {
  consumables: Consumable[];
}

export default function ConsumablesStatus({ consumables }: ConsumablesStatusProps) {
  const getStatusColor = (percentage: number) => {
    if (percentage > 70) return '#22c55e'; // green
    if (percentage > 30) return '#eab308'; // yellow
    return '#ef4444'; // red
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {consumables.map((item) => (
        <div key={item.type} className="flex flex-col items-center p-4 bg-dark-100 rounded-lg">
          <div className="w-20 h-20 mb-3">
            <CircularProgressbar
              value={100 - item.wearPercentage}
              text={`${100 - item.wearPercentage}%`}
              styles={buildStyles({
                pathColor: getStatusColor(100 - item.wearPercentage),
                textColor: 'white',
                trailColor: '#374151',
              })}
            />
          </div>
          <h3 className="text-sm font-medium text-white mb-1">{item.type}</h3>
          {item.brand && (
            <p className="text-xs text-gray-400 mb-1">{item.brand}</p>
          )}
          <p className="text-xs text-gray-400">
            Replace by: {new Date(item.expectedReplacement).toLocaleDateString()}
          </p>
        </div>
      ))}
    </div>
  );
} 