import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Save, X, Loader2, Wrench } from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Task, TaskStatus } from '../types/task';
import { toast } from 'react-hot-toast';

const DifficultyRating = ({ 
  value, 
  onChange 
}: { 
  value: number; 
  onChange: (value: number) => void; 
}) => {
  return (
    <div className="flex gap-2">
      {[1, 2, 3, 4, 5].map((rating) => (
        <button
          key={rating}
          type="button"
          onClick={() => onChange(rating)}
          className={`p-1 transition-colors ${
            rating <= value 
              ? 'text-primary-500 hover:text-primary-400' 
              : 'text-gray-600 hover:text-gray-500'
          }`}
        >
          <Wrench className="w-6 h-6" />
        </button>
      ))}
    </div>
  );
};

const PriorityShiftLights = ({ value }: { value: number }) => {
  const lights = [
    { threshold: 2, color: 'bg-green-500' },
    { threshold: 4, color: 'bg-green-400' },
    { threshold: 6, color: 'bg-yellow-400' },
    { threshold: 8, color: 'bg-red-400' },
    { threshold: 10, color: 'bg-red-500' }
  ];

  return (
    <div className="flex gap-1 mb-2">
      {lights.map(({ threshold, color }, index) => (
        <div
          key={threshold}
          className={`h-2 w-full rounded transition-all duration-200 ${
            value >= threshold ? color : 'bg-dark-50'
          } ${value === threshold ? 'animate-pulse' : ''}`}
        />
      ))}
    </div>
  );
};

interface TaskDetailProps {
  tasks: Task[];
  onUpdateTask: (task: Task) => Promise<void>;
}

export default function TaskDetail({ tasks, onUpdateTask }: TaskDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const isNew = id === 'new';

  const [task, setTask] = useState<Task>({
    id: isNew ? crypto.randomUUID() : id!,
    title: '',
    description: '',
    status: 'ready',
    priority: 1,
    difficulty: 1,
    dueDate: '',
    labels: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchTask() {
      if (!programId || isNew) {
        setIsLoading(false);
        return;
      }

      try {
        const taskRef = doc(db, 'programs', programId, 'tasks', id!);
        const taskSnap = await getDoc(taskRef);
        
        if (taskSnap.exists()) {
          setTask({ id: taskSnap.id, ...taskSnap.data() } as Task);
        } else {
          setError('Task not found');
          navigate('/tasks');
        }
      } catch (err) {
        console.error('Error fetching task:', err);
        setError('Failed to load task');
      } finally {
        setIsLoading(false);
      }
    }

    fetchTask();
  }, [id, programId, isNew, navigate]);

  const handleSave = async () => {
    if (!programId || !task.title) {
      setError('Please fill in all required fields');
      return;
    }

    setIsSaving(true);
    try {
      const taskRef = doc(db, 'programs', programId, 'tasks', task.id);
      await setDoc(taskRef, {
        ...task,
        updatedAt: new Date().toISOString()
      });
      
      toast.success(isNew ? 'Task created' : 'Task saved');
      navigate('/tasks');
    } catch (err) {
      console.error('Error saving task:', err);
      setError('Failed to save task');
      toast.error('Failed to save task');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-500/10 text-red-500 p-3 rounded-md">
          {error}
        </div>
      )}

      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/tasks')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">
            {isNew ? 'New Task' : task.title}
          </h1>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
          >
            {isSaving ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <Save className="w-4 h-4" />
            )}
            {isNew ? 'Create Task' : 'Save Changes'}
          </button>
          <button
            onClick={() => navigate('/tasks')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50"
          >
            <X className="w-4 h-4" />
            Cancel
          </button>
        </div>
      </div>

      {/* Task Form */}
      <div className="space-y-6">
        {/* Basic Info */}
        <div className="bg-dark-200 rounded-lg p-6">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Title
              </label>
              <input
                type="text"
                value={task.title}
                onChange={(e) => setTask(prev => ({ 
                  ...prev, 
                  title: e.target.value,
                  updatedAt: new Date().toISOString()
                }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                placeholder="Task title"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Description
              </label>
              <textarea
                value={task.description}
                onChange={(e) => setTask(prev => ({ 
                  ...prev, 
                  description: e.target.value,
                  updatedAt: new Date().toISOString()
                }))}
                rows={4}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
                placeholder="Task description"
              />
            </div>
          </div>
        </div>

        {/* Task Details */}
        <div className="bg-dark-200 rounded-lg p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-3">
                Status
              </label>
              <select
                value={task.status}
                onChange={(e) => setTask(prev => ({
                  ...prev,
                  status: e.target.value as TaskStatus,
                  updatedAt: new Date().toISOString()
                }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              >
                <option value="someday">Someday</option>
                <option value="ready">Ready</option>
                <option value="on-track">On Track</option>
                <option value="pit-stop">Pit Stop</option>
                <option value="done">Done</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-3">
                Due Date
              </label>
              <input
                type="date"
                value={task.dueDate ? task.dueDate.split('T')[0] : ''}
                onChange={(e) => setTask(prev => ({
                  ...prev,
                  dueDate: e.target.value ? new Date(e.target.value).toISOString() : '',
                  updatedAt: new Date().toISOString()
                }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-3">
                Priority (1-10)
              </label>
              <div className="space-y-2">
                <PriorityShiftLights value={task.priority} />
                <input
                  type="range"
                  min="1"
                  max="10"
                  value={task.priority}
                  onChange={(e) => setTask(prev => ({ 
                    ...prev, 
                    priority: Number(e.target.value),
                    updatedAt: new Date().toISOString()
                  }))}
                  className="w-full h-2 bg-dark-300 rounded-lg appearance-none cursor-pointer accent-primary-500"
                />
                <div className="flex justify-between text-sm text-gray-400">
                  <span>Low</span>
                  <span className={`font-medium ${
                    task.priority <= 2 ? 'text-green-500' :
                    task.priority <= 4 ? 'text-green-400' :
                    task.priority <= 6 ? 'text-yellow-400' :
                    task.priority <= 8 ? 'text-red-400' :
                    'text-red-500'
                  }`}>{task.priority}</span>
                  <span>High</span>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-3">
                Difficulty
              </label>
              <DifficultyRating
                value={task.difficulty}
                onChange={(value) => setTask(prev => ({
                  ...prev,
                  difficulty: value,
                  updatedAt: new Date().toISOString()
                }))}
              />
              <p className="mt-2 text-sm text-gray-400">
                {task.difficulty} wrench{task.difficulty !== 1 ? 'es' : ''} - {
                  task.difficulty === 1 ? 'Easy' :
                  task.difficulty === 2 ? 'Basic' :
                  task.difficulty === 3 ? 'Intermediate' :
                  task.difficulty === 4 ? 'Advanced' :
                  'Expert'
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}