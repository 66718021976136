import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Check, Loader2 } from 'lucide-react';
import { redirectToCheckout } from '../../services/stripeService';

export default function PaymentPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  if (!user) {
    return <Navigate to="/landing" replace />;
  }

  if (user.status !== 'pending') {
    return <Navigate to="/" replace />;
  }

  const plans = [
    {
      name: 'Monthly',
      price: 6,
      period: 'month',
      features: [
        'All core features',
        'Unlimited vehicles',
        'Event management',
        'Budget tracking',
        'Priority support'
      ]
    },
    {
      name: 'Annual',
      price: 60,
      period: 'year',
      features: [
        'All Monthly features',
        'Save 17%',
        'Advanced analytics',
        'API access',
        'Premium support'
      ],
      popular: true
    }
  ];

  const handleSelectPlan = async (planName: string) => {
    setSelectedPlan(planName);
    setIsProcessing(true);

    try {
      // Call the Stripe redirect function
      await redirectToCheckout();
    } catch (error) {
      console.error('Payment failed:', error);
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-dark-300 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-bold text-white">
          Choose your plan
        </h2>
        <p className="mt-2 text-center text-sm text-gray-400">
          Select a plan to continue setting up your account
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`bg-dark-200 rounded-lg p-6 ${
                plan.popular ? 'ring-2 ring-primary-500' : ''
              }`}
            >
              {plan.popular && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-500/10 text-primary-500">
                  Popular
                </span>
              )}
              <div className="mt-4">
                <h3 className="text-lg font-semibold text-white">
                  {plan.name}
                </h3>
                <p className="mt-2">
                  <span className="text-4xl font-bold text-white">
                    ${plan.price}
                  </span>
                  <span className="text-gray-400">/{plan.period}</span>
                </p>
                <ul className="mt-6 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center">
                      <Check className="h-5 w-5 text-primary-500" />
                      <span className="ml-3 text-gray-400">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleSelectPlan(plan.name)}
                  disabled={isProcessing}
                  className={`mt-8 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                    isProcessing && selectedPlan === plan.name
                      ? 'bg-primary-600 cursor-wait'
                      : plan.popular
                      ? 'bg-primary-500 hover:bg-primary-600'
                      : 'bg-dark-100 hover:bg-dark-50'
                  } disabled:opacity-50 disabled:cursor-not-allowed`}
                >
                  {isProcessing && selectedPlan === plan.name ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="w-4 h-4 animate-spin mr-2" />
                      Processing...
                    </div>
                  ) : (
                    `Select ${plan.name} Plan`
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}