import React from 'react';
import { Car, Edit, Trash2 } from 'lucide-react';
import type { Vehicle } from '../../types/vehicle';

interface VehicleCardProps {
  vehicle: Vehicle;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function VehicleCard({ vehicle, onClick, onEdit, onDelete }: VehicleCardProps) {
  return (
    <div 
      onClick={onClick}
      className="bg-dark-200 rounded-lg p-6 hover:bg-dark-100 transition-colors cursor-pointer"
    >
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center gap-3 flex-1 group">
          <div className="p-2 bg-primary-500/10 rounded-lg group-hover:bg-primary-500/20">
            <Car className="h-6 w-6 text-primary-500" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white group-hover:text-primary-500">
              {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            </h3>
            <p className="text-sm text-gray-400">{vehicle.primaryUse}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <Edit className="h-4 w-4" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className="p-2 text-gray-400 hover:text-red-500 rounded-lg hover:bg-dark-50"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div>
          <p className="text-gray-400">Power Output</p>
          <p className="text-white">{vehicle.power} hp</p>
        </div>
        <div>
          <p className="text-gray-400">Weight</p>
          <p className="text-white">{vehicle.weight} lbs</p>
        </div>
        <div>
          <p className="text-gray-400">Drivetrain</p>
          <p className="text-white">{vehicle.drivetrain}</p>
        </div>
        <div>
          <p className="text-gray-400">Primary Use</p>
          <p className="text-white">{vehicle.primaryUse}</p>
        </div>
      </div>
    </div>
  );
}