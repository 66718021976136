import React from 'react';
import { format, addDays, startOfWeek, eachDayOfInterval, isSameDay } from 'date-fns';
import type { Event } from '../../types/event';

interface WeekViewProps {
  currentDate: Date;
  events: Event[];
  onSelectDate: (date: Date) => void;
  onSelectEvent: (event: Event) => void;
}

export function WeekView({ currentDate, events, onSelectDate, onSelectEvent }: WeekViewProps) {
  const weekStart = startOfWeek(currentDate);
  const days = eachDayOfInterval({
    start: weekStart,
    end: addDays(weekStart, 6),
  });

  const hours = Array.from({ length: 24 }, (_, i) => i);

  const getEventColor = (type: Event['type']) => {
    switch (type) {
      case 'competition':
        return 'bg-red-500/20 text-red-400';
      case 'practice':
        return 'bg-blue-500/20 text-blue-400';
      case 'maintenance':
        return 'bg-yellow-500/20 text-yellow-400';
      case 'education':
        return 'bg-green-500/20 text-green-400';
      case 'exhibition':
        return 'bg-purple-500/20 text-purple-400';
      case 'testing':
        return 'bg-orange-500/20 text-orange-400';
      case 'media':
        return 'bg-pink-500/20 text-pink-400';
      case 'development':
        return 'bg-indigo-500/20 text-indigo-400';
      case 'sim':
        return 'bg-cyan-500/20 text-cyan-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  return (
    <div className="bg-dark-200 rounded-lg overflow-hidden">
      <div className="grid grid-cols-8 text-sm">
        {/* Time column */}
        <div className="border-r border-dark-50">
          <div className="h-16 border-b border-dark-50" /> {/* Empty corner */}
          {hours.map((hour) => (
            <div
              key={hour}
              className="h-20 border-b border-dark-50 px-2 py-1 text-gray-400"
            >
              {format(new Date().setHours(hour), 'ha')}
            </div>
          ))}
        </div>

        {/* Days columns */}
        {days.map((day) => {
          const dayEvents = events.filter((event) => isSameDay(new Date(event.date), day));
          
          return (
            <div
              key={day.toISOString()}
              className="border-r border-dark-50"
            >
              {/* Header */}
              <div className="h-16 border-b border-dark-50 p-2 text-center">
                <div className="text-gray-400">{format(day, 'EEE')}</div>
                <div className="text-white font-medium">{format(day, 'd')}</div>
              </div>

              {/* Time slots */}
              {hours.map((hour) => {
                const hourEvents = dayEvents.filter(
                  (event) => new Date(event.date).getHours() === hour
                );

                return (
                  <div
                    key={hour}
                    onClick={() => {
                      const date = new Date(day);
                      date.setHours(hour);
                      onSelectDate(date);
                    }}
                    className="h-20 border-b border-dark-50 relative cursor-pointer hover:bg-dark-100 transition-colors"
                  >
                    {hourEvents.map((event) => (
                      <button
                        key={event.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelectEvent(event);
                        }}
                        className={`absolute inset-x-0 mx-1 p-1 rounded-md text-xs text-left transition-colors hover:brightness-125 ${
                          getEventColor(event.type)
                        }`}
                      >
                        {event.title}
                      </button>
                    ))}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}