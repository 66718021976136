import React from 'react';
import { format } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';

type ViewType = 'month' | 'week' | 'day' | 'year';

interface CalendarHeaderProps {
  currentDate: Date;
  onDateChange: (date: Date) => void;
  view: ViewType;
  onViewChange: (view: ViewType) => void;
}

const views: ViewType[] = ['month', 'week', 'day', 'year'];

export function CalendarHeader({ currentDate, onDateChange, view, onViewChange }: CalendarHeaderProps) {
  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    switch (view) {
      case 'month':
        newDate.setMonth(currentDate.getMonth() - 1);
        break;
      case 'week':
        newDate.setDate(currentDate.getDate() - 7);
        break;
      case 'day':
        newDate.setDate(currentDate.getDate() - 1);
        break;
      case 'year':
        newDate.setFullYear(currentDate.getFullYear() - 1);
        break;
    }
    onDateChange(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    switch (view) {
      case 'month':
        newDate.setMonth(currentDate.getMonth() + 1);
        break;
      case 'week':
        newDate.setDate(currentDate.getDate() + 7);
        break;
      case 'day':
        newDate.setDate(currentDate.getDate() + 1);
        break;
      case 'year':
        newDate.setFullYear(currentDate.getFullYear() + 1);
        break;
    }
    onDateChange(newDate);
  };

  const getHeaderText = () => {
    switch (view) {
      case 'month':
        return format(currentDate, 'MMMM yyyy');
      case 'week':
        return `Week of ${format(currentDate, 'MMM d, yyyy')}`;
      case 'day':
        return format(currentDate, 'EEEE, MMMM d, yyyy');
      case 'year':
        return format(currentDate, 'yyyy');
      default:
        return '';
    }
  };

  return (
    <div className="flex items-center justify-between p-4 border-b border-dark-50">
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2">
          <button
            onClick={handlePrevious}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-full hover:bg-dark-50"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={handleNext}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-full hover:bg-dark-50"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
        <h2 className="text-2xl font-semibold text-white ml-2">{getHeaderText()}</h2>
      </div>
      <div className="flex gap-2">
        {views.map((v) => (
          <button
            key={v}
            onClick={() => onViewChange(v)}
            className={`px-4 py-2 rounded-md text-sm font-medium capitalize ${
              view === v
                ? 'bg-primary-500 text-white'
                : 'text-gray-400 hover:bg-dark-50'
            }`}
          >
            {v}
          </button>
        ))}
      </div>
    </div>
  );
}