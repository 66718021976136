import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Calendar, Car, Wrench, Clock, Plus, ChevronRight, 
  AlertTriangle, CheckCircle2, Flag, Settings, 
  ArrowUpRight
} from 'lucide-react';
import { format } from 'date-fns';
import StatCard from '../components/dashboard/StatCard';
import { useAuth } from '../contexts/AuthContext';
import type { Vehicle } from '../types/vehicle';
import type { Task } from '../types/task';
import type { TrackEvent } from '../types/event';
import type { Budget } from '../types/budget';

interface DashboardProps {
  events: TrackEvent[];
  vehicles: Vehicle[];
  tasks: Task[];
  budget: Budget | null;
}

export default function Dashboard({ events, vehicles, tasks, budget }: DashboardProps) {
  const navigate = useNavigate();
  const { programId } = useAuth();

  // Get upcoming events (next 30 days)
  const upcomingEvents = events
    .filter(event => {
      const eventDate = new Date(event.startDate);
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
      return eventDate <= thirtyDaysFromNow && eventDate >= new Date();
    })
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  // Get all future events
  const futureEvents = events
    .filter(event => new Date(event.startDate) >= new Date())
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  // Get high priority tasks
  const highPriorityTasks = tasks
    .filter(task => task.priority >= 6 && task.status !== 'done')
    .sort((a, b) => b.priority - a.priority);

  // Get maintenance tasks
  const maintenanceTasks = tasks
    .filter(task => task.labels?.includes('maintenance') && task.status !== 'done')
    .sort((a, b) => (b.priority || 0) - (a.priority || 0));

  // Get completed tasks count
  const completedTasksCount = tasks.filter(t => t.status === 'done').length;

  // Determine which sections to show
  const showUpcomingEvents = events.length > 0;
  const showHighPriorityTasks = highPriorityTasks.length > 0;
  const showMaintenanceTasks = maintenanceTasks.length > 0;
  const showVehicleStatus = vehicles.length > 0;

  // Get the most recently updated vehicle
  const primaryVehicle = vehicles.length > 0 
    ? vehicles.reduce((latest, current) => 
        new Date(current.updatedAt) > new Date(latest.updatedAt) ? current : latest
      , vehicles[0])
    : null;

  return (
    <div className="space-y-6">
      {/* Quick Actions Bar - Mobile Optimized */}
      <div className="bg-dark-200 rounded-lg p-4 lg:p-4">
        <div className="flex lg:hidden overflow-x-auto pb-2 -mb-2 scrollbar-hide">
          <div className="flex gap-8 px-2">
            <button
              onClick={() => navigate('/tasks/new')}
              className="flex flex-col items-center gap-1 min-w-[4rem]"
            >
              <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                <Plus className="w-6 h-6 text-primary-500" />
              </div>
              <span className="text-xs text-gray-400 whitespace-nowrap">New Task</span>
            </button>
            <button
              onClick={() => navigate('/schedule/new', { state: { isNew: true, date: new Date() }})}
              className="flex flex-col items-center gap-1 min-w-[4rem]"
            >
              <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                <Calendar className="w-6 h-6 text-primary-500" />
              </div>
              <span className="text-xs text-gray-400 whitespace-nowrap">Add Event</span>
            </button>
            {!vehicles.length && (
              <button
                onClick={() => navigate('/garage/vehicles/new')}
                className="flex flex-col items-center gap-1 min-w-[4rem]"
              >
                <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                  <Car className="w-6 h-6 text-primary-500" />
                </div>
                <span className="text-xs text-gray-400 whitespace-nowrap">Add Vehicle</span>
              </button>
            )}
            {!budget && (
              <button
                onClick={() => navigate('/budget')}
                className="flex flex-col items-center gap-1 min-w-[4rem]"
              >
                <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                  <Settings className="w-6 h-6 text-primary-500" />
                </div>
                <span className="text-xs text-gray-400 whitespace-nowrap">Set Budget</span>
              </button>
            )}
          </div>
        </div>

        {/* Desktop Quick Actions */}
        <div className="hidden lg:flex flex-wrap gap-4">
          <button
            onClick={() => navigate('/tasks/new')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
          >
            <Plus className="w-4 h-4" />
            New Task
          </button>
          <button
            onClick={() => navigate('/schedule/new', { state: { isNew: true, date: new Date() }})}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
          >
            <Plus className="w-4 h-4" />
            Add Event
          </button>
          {!vehicles.length && (
            <button
              onClick={() => navigate('/garage/vehicles/new')}
              className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
            >
              <Plus className="w-4 h-4" />
              Add Vehicle
            </button>
          )}
          {!budget && (
            <button
              onClick={() => navigate('/budget')}
              className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
            >
              <Plus className="w-4 h-4" />
              Set Up Budget
            </button>
          )}
        </div>
      </div>

      {!vehicles.length ? (
        // Show Get Started section only if they have no vehicles
        <div className="bg-dark-200 rounded-lg p-6">
          <h2 className="text-lg font-semibold text-white mb-4">Get Started</h2>
          <div className="space-y-3">
            <Link
              to="/garage/vehicles/new"
              className="flex items-center justify-between p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
            >
              <div className="flex items-center gap-3">
                <div className="p-2 bg-primary-500/10 rounded-lg">
                  <Car className="w-5 h-5 text-primary-500" />
                </div>
                <div>
                  <h3 className="text-white font-medium group-hover:text-primary-500">
                    Add Your First Vehicle
                  </h3>
                  <p className="text-sm text-gray-400">Set up your garage to get started</p>
                </div>
              </div>
              <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column - Vehicle Status & Events */}
          <div className="space-y-6">
            {/* Vehicle Quick Status */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-white">Primary Vehicle</h2>
                <Link 
                  to="/garage"
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  View All
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
              {primaryVehicle && (
                <Link
                  to={`/garage/vehicles/${primaryVehicle.id}`}
                  className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                >
                  <div className="p-2 bg-primary-500/10 rounded-lg">
                    <Car className="w-5 h-5 text-primary-500" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="text-white font-medium truncate group-hover:text-primary-500">
                      {primaryVehicle.name || `${primaryVehicle.year} ${primaryVehicle.make} ${primaryVehicle.model}`}
                    </h3>
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <span>{primaryVehicle.categories[0]}</span>
                        {primaryVehicle.primaryUse && (
                          <>
                            <span>•</span>
                            <span>{primaryVehicle.primaryUse}</span>
                          </>
                        )}
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <span>{primaryVehicle.power}hp</span>
                        <span>•</span>
                        <span>{primaryVehicle.drivetrain}</span>
                      </div>
                    </div>
                  </div>
                  <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                </Link>
              )}
              {vehicles.length > 1 && (
                <div className="mt-3 text-sm text-gray-400">
                  +{vehicles.length - 1} more {vehicles.length - 1 === 1 ? 'vehicle' : 'vehicles'}
                </div>
              )}
            </div>

            {/* Upcoming Events Section */}
            {showUpcomingEvents ? (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Upcoming Events</h2>
                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => navigate('/schedule/new', { state: { isNew: true, date: new Date() }})}
                      className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                    >
                      Add Event
                      <Plus className="w-4 h-4" />
                    </button>
                    <Link 
                      to="/schedule"
                      className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                    >
                      View All
                      <ChevronRight className="w-4 h-4" />
                    </Link>
                  </div>
                </div>
                <div className="space-y-3">
                  {futureEvents.slice(0, 3).map(event => (
                    <Link
                      key={event.id}
                      to={`/schedule/${event.id}`}
                      className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                    >
                      <div className="p-2 bg-primary-500/10 rounded-lg">
                        <Calendar className="w-5 h-5 text-primary-500" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="text-white font-medium truncate group-hover:text-primary-500">
                          {event.title}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {format(new Date(event.startDate), 'MMM d, yyyy')}
                        </p>
                      </div>
                      <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Schedule Events</h2>
                  <Link
                    to="/schedule/new"
                    state={{ isNew: true, date: new Date() }}
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Add Event
                    <Plus className="w-4 h-4" />
                  </Link>
                </div>
                <Link
                  to="/schedule/new"
                  state={{ isNew: true, date: new Date() }}
                  className="flex items-center justify-between p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                >
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-primary-500/10 rounded-lg">
                      <Calendar className="w-5 h-5 text-primary-500" />
                    </div>
                    <div>
                      <h3 className="text-white font-medium group-hover:text-primary-500">
                        Schedule Your First Event
                      </h3>
                      <p className="text-sm text-gray-400">Plan your racing calendar</p>
                    </div>
                  </div>
                  <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                </Link>
              </div>
            )}
          </div>

          {/* Middle Column - Tasks & Maintenance */}
          <div className="space-y-6">
            {/* High Priority Tasks */}
            {showHighPriorityTasks ? (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">High Priority</h2>
                  <Link 
                    to="/tasks"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    View All
                    <ChevronRight className="w-4 h-4" />
                  </Link>
                </div>
                <div className="space-y-3">
                  {highPriorityTasks.slice(0, 3).map(task => (
                    <Link
                      key={task.id}
                      to={`/tasks/${task.id}`}
                      className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                    >
                      <div className="p-2 bg-red-500/10 rounded-lg">
                        <AlertTriangle className="w-5 h-5 text-red-500" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="text-white font-medium truncate group-hover:text-primary-500">
                          {task.title}
                        </h3>
                        <p className="text-sm text-gray-400">
                          Priority Level: {task.priority}
                        </p>
                      </div>
                      <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Tasks</h2>
                  <Link 
                    to="/tasks/new"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Add Task
                    <Plus className="w-4 h-4" />
                  </Link>
                </div>
                <Link
                  to="/tasks/new"
                  className="flex items-center justify-between p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                >
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-primary-500/10 rounded-lg">
                      <Flag className="w-5 h-5 text-primary-500" />
                    </div>
                    <div>
                      <h3 className="text-white font-medium group-hover:text-primary-500">
                        Create Your First Task
                      </h3>
                      <p className="text-sm text-gray-400">Track work that needs to be done</p>
                    </div>
                  </div>
                  <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                </Link>
              </div>
            )}

            {/* Maintenance Tasks */}
            {showMaintenanceTasks && (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Maintenance</h2>
                  <button
                    onClick={() => navigate('/tasks/new', { state: { defaultLabel: 'maintenance' } })}
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Add Task
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
                <div className="space-y-3">
                  {maintenanceTasks.slice(0, 3).map(task => (
                    <Link
                      key={task.id}
                      to={`/tasks/${task.id}`}
                      className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                    >
                      <div className="p-2 bg-yellow-500/10 rounded-lg">
                        <Wrench className="w-5 h-5 text-yellow-500" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="text-white font-medium truncate group-hover:text-primary-500">
                          {task.title}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {task.status === 'pit-stop' ? 'Urgent' : 'Scheduled'}
                        </p>
                      </div>
                      <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Right Column - Stats & Overview */}
          <div className="space-y-6">
            {/* Stats Overview */}
            <div className="bg-dark-200 rounded-lg p-6">
              <h2 className="text-lg font-semibold text-white mb-4">Overview</h2>
              <div className="grid grid-cols-2 gap-4">
                <Link
                  to="/garage"
                  className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors group"
                >
                  <div className="flex items-center gap-2 text-primary-500 mb-2">
                    <Car className="w-4 h-4" />
                    <span className="text-sm">Vehicles</span>
                  </div>
                  <p className="text-2xl font-semibold text-white group-hover:text-primary-500">{vehicles.length}</p>
                  <p className="text-xs text-gray-400">In garage</p>
                </Link>
                {showUpcomingEvents && (
                  <Link
                    to="/schedule"
                    className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors group"
                  >
                    <div className="flex items-center gap-2 text-primary-500 mb-2">
                      <Calendar className="w-4 h-4" />
                      <span className="text-sm">Events</span>
                    </div>
                    <p className="text-2xl font-semibold text-white group-hover:text-primary-500">{upcomingEvents.length}</p>
                    <p className="text-xs text-gray-400">Next 30 days</p>
                  </Link>
                )}
                {showMaintenanceTasks && (
                  <Link
                    to="/tasks"
                    className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors group"
                  >
                    <div className="flex items-center gap-2 text-yellow-500 mb-2">
                      <Wrench className="w-4 h-4" />
                      <span className="text-sm">Maintenance</span>
                    </div>
                    <p className="text-2xl font-semibold text-white group-hover:text-primary-500">{maintenanceTasks.length}</p>
                    <p className="text-xs text-gray-400">Tasks pending</p>
                  </Link>
                )}
                {showHighPriorityTasks && (
                  <Link
                    to="/tasks"
                    className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors group"
                  >
                    <div className="flex items-center gap-2 text-red-500 mb-2">
                      <AlertTriangle className="w-4 h-4" />
                      <span className="text-sm">Priority</span>
                    </div>
                    <p className="text-2xl font-semibold text-white group-hover:text-primary-500">{highPriorityTasks.length}</p>
                    <p className="text-xs text-gray-400">High priority tasks</p>
                  </Link>
                )}
                {completedTasksCount > 0 && (
                  <Link
                    to="/tasks"
                    className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors group"
                  >
                    <div className="flex items-center gap-2 text-green-500 mb-2">
                      <CheckCircle2 className="w-4 h-4" />
                      <span className="text-sm">Complete</span>
                    </div>
                    <p className="text-2xl font-semibold text-white group-hover:text-primary-500">{completedTasksCount}</p>
                    <p className="text-xs text-gray-400">Tasks completed</p>
                  </Link>
                )}
              </div>
            </div>

            {/* Budget Setup Prompt (if not set up) */}
            {!budget && (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Budget</h2>
                  <Link 
                    to="/budget"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Set Up
                    <Plus className="w-4 h-4" />
                  </Link>
                </div>
                <Link
                  to="/budget"
                  className="flex items-center justify-between p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                >
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-primary-500/10 rounded-lg">
                      <Settings className="w-5 h-5 text-primary-500" />
                    </div>
                    <div>
                      <h3 className="text-white font-medium group-hover:text-primary-500">
                        Set Up Budget
                      </h3>
                      <p className="text-sm text-gray-400">Track expenses & plan ahead</p>
                    </div>
                  </div>
                  <ArrowUpRight className="w-4 h-4 text-gray-400 group-hover:text-primary-500" />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}