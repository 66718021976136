import React from 'react';
import { format, isSameDay } from 'date-fns';
import type { Event } from '../../types/event';

interface DayViewProps {
  currentDate: Date;
  events: Event[];
  onSelectDate: (date: Date) => void;
  onSelectEvent: (event: Event) => void;
}

export function DayView({ currentDate, events, onSelectDate, onSelectEvent }: DayViewProps) {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const dayEvents = events.filter((event) => isSameDay(new Date(event.date), currentDate));

  const getEventColor = (type: Event['type']) => {
    switch (type) {
      case 'competition':
        return 'bg-red-500/20 text-red-400';
      case 'practice':
        return 'bg-blue-500/20 text-blue-400';
      case 'maintenance':
        return 'bg-yellow-500/20 text-yellow-400';
      case 'education':
        return 'bg-green-500/20 text-green-400';
      case 'exhibition':
        return 'bg-purple-500/20 text-purple-400';
      case 'testing':
        return 'bg-orange-500/20 text-orange-400';
      case 'media':
        return 'bg-pink-500/20 text-pink-400';
      case 'development':
        return 'bg-indigo-500/20 text-indigo-400';
      case 'sim':
        return 'bg-cyan-500/20 text-cyan-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  return (
    <div className="bg-dark-200 rounded-lg overflow-hidden">
      <div className="grid grid-cols-1 sm:grid-cols-[100px_1fr] text-sm">
        {/* Time column */}
        <div className="hidden sm:block border-r border-dark-50">
          {hours.map((hour) => (
            <div
              key={hour}
              className="h-20 border-b border-dark-50 px-2 py-1 text-gray-400"
            >
              {format(new Date().setHours(hour), 'ha')}
            </div>
          ))}
        </div>

        {/* Events column */}
        <div>
          {hours.map((hour) => {
            const hourEvents = dayEvents.filter(
              (event) => new Date(event.date).getHours() === hour
            );

            return (
              <div
                key={hour}
                className="h-20 border-b border-dark-50 relative cursor-pointer hover:bg-dark-100 transition-colors group"
                onClick={() => {
                  const date = new Date(currentDate);
                  date.setHours(hour);
                  onSelectDate(date);
                }}
              >
                <div className="sm:hidden absolute left-0 top-0 text-gray-400 text-xs p-1">
                  {format(new Date().setHours(hour), 'ha')}
                </div>
                {hourEvents.map((event) => (
                  <button
                    key={event.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectEvent(event);
                    }}
                    className={`absolute inset-x-0 mx-1 mt-6 sm:mt-1 p-1 rounded-md text-xs text-left transition-colors hover:brightness-125 ${
                      getEventColor(event.type)
                    }`}
                  >
                    {event.title}
                  </button>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}