import React from 'react';
import { Link } from 'react-router-dom';
import { ClipboardList, Edit, Trash2 } from 'lucide-react';
import type { Checklist } from '../../types/checklist';
import { formatDistanceToNow } from 'date-fns';

interface ChecklistCardProps {
  checklist: Checklist;
  onEdit: (checklist: Checklist) => void;
  onDelete: (id: string) => void;
}

export default function ChecklistCard({ checklist, onEdit, onDelete }: ChecklistCardProps) {
  const completedItems = checklist.items.filter(item => item.completed).length;
  const totalItems = checklist.items.length;
  const progress = totalItems > 0 ? (completedItems / totalItems) * 100 : 0;

  return (
    <div className="bg-dark-200 rounded-lg p-6 hover:bg-dark-100 transition-colors">
      <div className="flex justify-between items-start mb-4">
        <Link 
          to={`/checklists/${checklist.id}`}
          className="flex items-center gap-3 flex-1 group"
        >
          <div className="p-2 bg-primary-500/10 rounded-lg group-hover:bg-primary-500/20">
            <ClipboardList className="h-6 w-6 text-primary-500" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white group-hover:text-primary-500">
              {checklist.name}
            </h3>
            <p className="text-sm text-gray-400">{checklist.category}</p>
          </div>
        </Link>
        <div className="flex gap-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              onEdit(checklist);
            }}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <Edit className="h-4 w-4" />
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              onDelete(checklist.id);
            }}
            className="p-2 text-gray-400 hover:text-red-500 rounded-lg hover:bg-dark-50"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>
      
      <Link 
        to={`/checklists/${checklist.id}`}
        className="block space-y-4"
      >
        {checklist.description && (
          <p className="text-sm text-gray-400">{checklist.description}</p>
        )}

        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-400">Progress</span>
            <span className="text-white">{completedItems}/{totalItems} items</span>
          </div>
          <div className="h-2 bg-dark-300 rounded-full overflow-hidden">
            <div 
              className="h-full bg-primary-500 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>

        <div className="pt-4 border-t border-dark-50 text-sm text-gray-400">
          Last updated {formatDistanceToNow(new Date(checklist.updatedAt))} ago
        </div>
      </Link>
    </div>
  );
}