import React from 'react';
import {
  startOfYear,
  endOfYear,
  eachMonthOfInterval,
  format,
  isSameMonth,
  isWithinInterval,
} from 'date-fns';
import type { Event } from '../../types/event';

interface YearViewProps {
  currentDate: Date;
  events: Event[];
  onSelectDate: (date: Date) => void;
  onSelectEvent: (event: Event) => void;
}

export function YearView({ currentDate, events, onSelectDate, onSelectEvent }: YearViewProps) {
  const yearStart = startOfYear(currentDate);
  const yearEnd = endOfYear(currentDate);
  const months = eachMonthOfInterval({ start: yearStart, end: yearEnd });

  const getEventColor = (type: Event['type']) => {
    switch (type) {
      case 'competition':
        return 'bg-red-500/20 text-red-400';
      case 'practice':
        return 'bg-blue-500/20 text-blue-400';
      case 'maintenance':
        return 'bg-yellow-500/20 text-yellow-400';
      case 'education':
        return 'bg-green-500/20 text-green-400';
      case 'exhibition':
        return 'bg-purple-500/20 text-purple-400';
      case 'testing':
        return 'bg-orange-500/20 text-orange-400';
      case 'media':
        return 'bg-pink-500/20 text-pink-400';
      case 'development':
        return 'bg-indigo-500/20 text-indigo-400';
      case 'sim':
        return 'bg-cyan-500/20 text-cyan-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {months.map((month, monthIndex) => {
        const monthEvents = events.filter(event => 
          isSameMonth(new Date(event.date), month)
        );

        return (
          <div
            key={`month-${monthIndex}`}
            className="bg-dark-100 rounded-lg p-4 cursor-pointer hover:bg-dark-50"
            onClick={() => onSelectDate(month)}
          >
            <h3 className="text-lg font-semibold text-white mb-2">
              {format(month, 'MMMM')}
            </h3>
            <div className="space-y-2">
              {monthEvents.length > 0 ? (
                monthEvents.map(event => (
                  <button
                    key={`event-${event.id}-${monthIndex}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectEvent(event);
                    }}
                    className={`w-full px-2 py-1 text-xs rounded-md truncate text-left ${
                      getEventColor(event.type)
                    }`}
                  >
                    {event.title}
                  </button>
                ))
              ) : (
                <p className="text-sm text-gray-400">No events</p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}