import React from 'react';
import { Icon } from '../../icons';

interface MaintenanceItem {
  type: string;
  icon: string;
  nextService: string;
  interval: string;
}

interface RecommendedMaintenanceProps {
  items: MaintenanceItem[];
}

const defaultItems: MaintenanceItem[] = [
  {
    type: 'Transmission Fluid',
    icon: 'stick-shift-gear-speed',
    nextService: '2024-06-15',
    interval: '30,000 miles'
  },
  // Add more default items here
];

export default function RecommendedMaintenance({ items = defaultItems }: RecommendedMaintenanceProps) {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-white">Recommended Maintenance</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {items.map((item) => (
          <div 
            key={item.type}
            className="flex items-start gap-3 p-4 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors"
          >
            <div className="p-2 bg-primary-500/10 rounded-lg">
              <Icon 
                name={item.icon} 
                size={24} 
                className="text-primary-500"
              />
            </div>
            <div>
              <h4 className="text-white font-medium">{item.type}</h4>
              <p className="text-sm text-gray-400">
                Next service: {new Date(item.nextService).toLocaleDateString()}
              </p>
              <p className="text-xs text-gray-400">
                Interval: {item.interval}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 