import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, isSameMonth, startOfYear, endOfYear, eachMonthOfInterval } from 'date-fns';
import type { TrackEvent } from '../../types/event';

interface SeasonTimelineProps {
  events: TrackEvent[];
}

export function SeasonTimeline({ events }: SeasonTimelineProps) {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const yearStart = startOfYear(new Date());
  const yearEnd = endOfYear(new Date());
  
  // Get all months in the year
  const months = eachMonthOfInterval({
    start: yearStart,
    end: yearEnd
  });

  // Group events by month
  const eventsByMonth = months.map(month => {
    const monthEvents = events.filter(event => 
      isSameMonth(new Date(event.startDate), month)
    );
    return {
      month,
      events: monthEvents
    };
  });

  const getEventColor = (type: TrackEvent['type']) => {
    switch (type) {
      case 'race':
        return 'bg-red-500';
      case 'practice':
        return 'bg-blue-500';
      case 'maintenance':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <div className="bg-dark-200 rounded-lg p-6 mb-6">
      <h2 className="text-lg font-semibold text-white mb-4">Season Timeline</h2>
      <div className="relative">
        {/* Timeline line */}
        <div className="absolute h-1 bg-dark-50 left-0 right-0 top-[2.5rem]" />
        
        {/* Months */}
        <div className="grid grid-cols-12 gap-1">
          {eventsByMonth.map(({ month, events }) => (
            <div key={month.toString()} className="text-center">
              <div className="text-sm text-gray-400 mb-2">
                {format(month, 'MMM')}
              </div>
              <div className="relative min-h-[3rem] flex flex-col items-center gap-1">
                {events.map(event => (
                  <button
                    key={event.id}
                    onClick={() => navigate(`/schedule/${event.id}`)}
                    className={`
                      w-3 h-3 rounded-full ${getEventColor(event.type)}
                      hover:ring-2 hover:ring-offset-2 hover:ring-offset-dark-200 hover:ring-white
                      transition-all cursor-pointer
                      group relative
                    `}
                  >
                    {/* Tooltip */}
                    <div className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 w-max opacity-0 group-hover:opacity-100 transition-opacity">
                      <div className="bg-dark-100 text-white text-xs rounded px-2 py-1 whitespace-nowrap">
                        {event.title}
                        <div className="text-gray-400">
                          {format(new Date(event.startDate), 'MMM d')}
                        </div>
                      </div>
                      <div className="border-t-4 border-x-4 border-transparent border-t-dark-100 w-0 h-0 mx-auto" />
                    </div>
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
} 