import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Edit, Trash2, Save, X, Car, Calendar, Sparkles, Loader2 } from 'lucide-react';
import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Checklist, ChecklistCategory, ChecklistItem } from '../types/checklist';
import { toast } from 'react-hot-toast';

const categories: ChecklistCategory[] = [
  'Event Prep',
  'Track Setup',
  'Maintenance',
  'Packing List',
  'Documentation',
  'Safety',
  'Sponsorship',
  'Other'
];

interface ChecklistDetailProps {
  checklists: Checklist[];
  onUpdateChecklist: (checklist: Checklist) => Promise<void>;
}

export default function ChecklistDetail({ checklists, onUpdateChecklist }: ChecklistDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const isNew = id === 'new';
  
  const [checklist, setChecklist] = useState<Checklist>({
    id: isNew ? crypto.randomUUID() : id!,
    name: '',
    category: 'Event Prep',
    description: '',
    items: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [newItemText, setNewItemText] = useState('');
  const [newItemNotes, setNewItemNotes] = useState('');
  const [editingItem, setEditingItem] = useState<string | null>(null);

  useEffect(() => {
    async function fetchChecklist() {
      if (!programId || isNew) {
        setIsLoading(false);
        return;
      }

      try {
        const checklistRef = doc(db, 'programs', programId, 'checklists', id!);
        const checklistSnap = await getDoc(checklistRef);
        
        if (checklistSnap.exists()) {
          setChecklist({ id: checklistSnap.id, ...checklistSnap.data() } as Checklist);
        } else {
          setError('Checklist not found');
          navigate('/checklists');
        }
      } catch (err) {
        console.error('Error fetching checklist:', err);
        setError('Failed to load checklist');
      } finally {
        setIsLoading(false);
      }
    }

    fetchChecklist();
  }, [id, programId, isNew, navigate]);

  const handleAddItem = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newItemText.trim()) return;

    const newItem: ChecklistItem = {
      id: crypto.randomUUID(),
      text: newItemText.trim(),
      notes: newItemNotes.trim() || undefined,
      completed: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    setChecklist(prev => ({
      ...prev,
      items: [...prev.items, newItem],
      updatedAt: new Date().toISOString(),
    }));

    setNewItemText('');
    setNewItemNotes('');
  };

  const handleToggleItem = async (itemId: string) => {
    setChecklist(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === itemId
          ? { ...item, completed: !item.completed, updatedAt: new Date().toISOString() }
          : item
      ),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleUpdateItem = async (itemId: string, updates: Partial<ChecklistItem>) => {
    setChecklist(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === itemId
          ? { ...item, ...updates, updatedAt: new Date().toISOString() }
          : item
      ),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleDeleteItem = async (itemId: string) => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    setChecklist(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== itemId),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleSave = async () => {
    if (!programId || !checklist.name) {
      setError('Please fill in all required fields');
      return;
    }

    setIsSaving(true);
    try {
      const checklistRef = doc(db, 'programs', programId, 'checklists', checklist.id);
      await setDoc(checklistRef, {
        ...checklist,
        updatedAt: new Date().toISOString()
      });
      
      toast.success(isNew ? 'Checklist created' : 'Checklist saved');
      navigate('/checklists');
    } catch (err) {
      console.error('Error saving checklist:', err);
      setError('Failed to save checklist');
      toast.error('Failed to save checklist');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-500/10 text-red-500 p-3 rounded-md">
          {error}
        </div>
      )}

      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/checklists')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">
            {isNew ? 'New Checklist' : checklist.name}
          </h1>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
          >
            {isSaving ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <Save className="w-4 h-4" />
            )}
            {isNew ? 'Create Checklist' : 'Save Changes'}
          </button>
          <button
            onClick={() => navigate('/checklists')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50"
          >
            <X className="w-4 h-4" />
            Cancel
          </button>
        </div>
      </div>

      {/* Basic Info */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Name
            </label>
            <input
              type="text"
              value={checklist.name}
              onChange={(e) => setChecklist(prev => ({ 
                ...prev, 
                name: e.target.value,
                updatedAt: new Date().toISOString()
              }))}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              placeholder="e.g., Track Day Preparation"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Category
            </label>
            <select
              value={checklist.category}
              onChange={(e) => setChecklist(prev => ({ 
                ...prev, 
                category: e.target.value as ChecklistCategory,
                updatedAt: new Date().toISOString()
              }))}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-400 mb-1">
            Description (Optional)
          </label>
          <textarea
            value={checklist.description}
            onChange={(e) => setChecklist(prev => ({ 
              ...prev, 
              description: e.target.value,
              updatedAt: new Date().toISOString()
            }))}
            rows={3}
            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            placeholder="Add a description for this checklist..."
          />
        </div>
      </div>

      {/* Checklist Items */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold text-white">Items</h2>
        </div>

        {/* Add Item Form */}
        <form onSubmit={handleAddItem} className="mb-6">
          <div className="space-y-4">
            <input
              type="text"
              value={newItemText}
              onChange={(e) => setNewItemText(e.target.value)}
              placeholder="Add new item..."
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
            <textarea
              value={newItemNotes}
              onChange={(e) => setNewItemNotes(e.target.value)}
              placeholder="Add notes (optional)..."
              rows={2}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={!newItemText.trim()}
                className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Plus className="w-4 h-4" />
                Add Item
              </button>
            </div>
          </div>
        </form>

        {/* Items List */}
        <div className="space-y-2">
          {checklist.items.length === 0 ? (
            <p className="text-gray-400 text-center py-4">No items in this checklist yet.</p>
          ) : (
            checklist.items.map((item) => (
              <div
                key={item.id}
                className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50"
              >
                <div className="flex items-start gap-4">
                  <input
                    type="checkbox"
                    checked={item.completed}
                    onChange={() => handleToggleItem(item.id)}
                    className="mt-1 rounded border-dark-50 bg-dark-300 text-primary-500 focus:ring-primary-500"
                  />
                  <div className="flex-1">
                    {editingItem === item.id ? (
                      <div className="space-y-2">
                        <input
                          type="text"
                          value={item.text}
                          onChange={(e) =>
                            handleUpdateItem(item.id, { text: e.target.value })
                          }
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                        />
                        <textarea
                          value={item.notes || ''}
                          onChange={(e) =>
                            handleUpdateItem(item.id, { notes: e.target.value })
                          }
                          placeholder="Add notes..."
                          rows={2}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                        />
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={() => setEditingItem(null)}
                            className="px-3 py-1 text-sm text-gray-400 hover:text-gray-300"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => setEditingItem(null)}
                            className="px-3 py-1 text-sm bg-primary-500 text-white rounded-md hover:bg-primary-600"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <p className={`text-white ${item.completed ? 'line-through text-gray-400' : ''}`}>
                          {item.text}
                        </p>
                        {item.notes && (
                          <p className="mt-1 text-sm text-gray-400">{item.notes}</p>
                        )}
                      </>
                    )}
                  </div>
                  {editingItem !== item.id && (
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEditingItem(item.id)}
                        className="p-1 text-gray-400 hover:text-primary-500"
                      >
                        <Edit className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteItem(item.id)}
                        className="p-1 text-gray-400 hover:text-red-500"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}