import React, { useState } from 'react';
import { Plus, Trash2, TrendingUp, TrendingDown } from 'lucide-react';
import type { Budget, BudgetItem } from '../../types/budget';
import { BudgetItemModal } from './BudgetItemModal';
import { formatCurrency } from '../../utils/format';

interface SeasonBudgetProps {
  budget: Budget;
  onUpdate: (updatedBudget: Budget) => Promise<void>;
}

export function SeasonBudget({ budget, onUpdate }: SeasonBudgetProps) {
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);

  const totalExpenses = budget.seasonExpenses.reduce((sum, item) => sum + item.amount, 0);
  const totalIncome = budget.seasonIncome.reduce((sum, item) => sum + item.amount, 0);
  const balance = totalIncome - totalExpenses;

  const handleAddItem = async (newItem: BudgetItem, type: 'expense' | 'income') => {
    const updatedBudget = { ...budget };
    if (type === 'expense') {
      updatedBudget.seasonExpenses = [...budget.seasonExpenses, newItem];
    } else {
      updatedBudget.seasonIncome = [...budget.seasonIncome, newItem];
    }
    await onUpdate(updatedBudget);
  };

  const handleDeleteItem = async (type: 'expense' | 'income', itemId: string) => {
    const updatedBudget = { ...budget };
    if (type === 'expense') {
      updatedBudget.seasonExpenses = budget.seasonExpenses.filter(item => item.id !== itemId);
    } else {
      updatedBudget.seasonIncome = budget.seasonIncome.filter(item => item.id !== itemId);
    }
    await onUpdate(updatedBudget);
  };

  return (
    <div className="p-4 space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-dark-300 p-4 rounded-lg border border-dark-50">
          <h4 className="text-gray-400 text-sm mb-2">Total Income</h4>
          <div className="flex items-center gap-2">
            <TrendingUp className="w-5 h-5 text-green-500" />
            <span className="text-2xl font-semibold text-green-500">
              {formatCurrency(totalIncome)}
            </span>
          </div>
        </div>
        <div className="bg-dark-300 p-4 rounded-lg border border-dark-50">
          <h4 className="text-gray-400 text-sm mb-2">Total Expenses</h4>
          <div className="flex items-center gap-2">
            <TrendingDown className="w-5 h-5 text-red-500" />
            <span className="text-2xl font-semibold text-red-500">
              {formatCurrency(totalExpenses)}
            </span>
          </div>
        </div>
        <div className="bg-dark-300 p-4 rounded-lg border border-dark-50">
          <h4 className="text-gray-400 text-sm mb-2">Balance</h4>
          <span className={`text-2xl font-semibold ${balance >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            {formatCurrency(balance)}
          </span>
        </div>
      </div>

      {/* Expenses Section */}
      <div className="bg-dark-300 rounded-lg border border-dark-50 p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-white">Season Expenses</h3>
          <button
            onClick={() => setIsExpenseModalOpen(true)}
            className="flex items-center gap-2 bg-primary-500 text-white rounded-md px-4 py-2 hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Expense
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b border-dark-50">
                <th className="pb-2 text-gray-400 font-medium">Category</th>
                <th className="pb-2 text-gray-400 font-medium">Description</th>
                <th className="pb-2 text-gray-400 font-medium">Amount</th>
                <th className="pb-2 text-gray-400 font-medium">Frequency</th>
                <th className="pb-2 text-gray-400 font-medium"></th>
              </tr>
            </thead>
            <tbody>
              {budget.seasonExpenses.map((item) => (
                <tr key={item.id} className="border-b border-dark-50 hover:bg-dark-200">
                  <td className="py-3 text-white">{item.category}</td>
                  <td className="py-3 text-white">{item.description}</td>
                  <td className="py-3 text-red-500">{formatCurrency(item.amount)}</td>
                  <td className="py-3 text-white capitalize">{item.frequency.replace('-', ' ')}</td>
                  <td className="py-3">
                    <button
                      onClick={() => handleDeleteItem('expense', item.id)}
                      className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Income Section */}
      <div className="bg-dark-300 rounded-lg border border-dark-50 p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-white">Season Income</h3>
          <button
            onClick={() => setIsIncomeModalOpen(true)}
            className="flex items-center gap-2 bg-primary-500 text-white rounded-md px-4 py-2 hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Income
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b border-dark-50">
                <th className="pb-2 text-gray-400 font-medium">Category</th>
                <th className="pb-2 text-gray-400 font-medium">Description</th>
                <th className="pb-2 text-gray-400 font-medium">Amount</th>
                <th className="pb-2 text-gray-400 font-medium">Frequency</th>
                <th className="pb-2 text-gray-400 font-medium"></th>
              </tr>
            </thead>
            <tbody>
              {budget.seasonIncome.map((item) => (
                <tr key={item.id} className="border-b border-dark-50 hover:bg-dark-200">
                  <td className="py-3 text-white">{item.category}</td>
                  <td className="py-3 text-white">{item.description}</td>
                  <td className="py-3 text-green-500">{formatCurrency(item.amount)}</td>
                  <td className="py-3 text-white capitalize">{item.frequency.replace('-', ' ')}</td>
                  <td className="py-3">
                    <button
                      onClick={() => handleDeleteItem('income', item.id)}
                      className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <BudgetItemModal
        isOpen={isExpenseModalOpen}
        onClose={() => setIsExpenseModalOpen(false)}
        onAdd={(item) => handleAddItem(item, 'expense')}
        type="expense"
      />

      <BudgetItemModal
        isOpen={isIncomeModalOpen}
        onClose={() => setIsIncomeModalOpen(false)}
        onAdd={(item) => handleAddItem(item, 'income')}
        type="income"
      />
    </div>
  );
}