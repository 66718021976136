import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, setDoc, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { MonthlyTrends } from '../components/budget/MonthlyTrends';
import { ExpenseBreakdown } from '../components/budget/ExpenseBreakdown';
import { BudgetSummaryPanel } from '../components/budget/BudgetSummaryPanel';
import { SeasonBudget } from '../components/budget/SeasonBudget';
import type { Budget, ExpenseCategory } from '../types/budget';
import { toast } from 'react-hot-toast';
import { Loader2, Plus } from 'lucide-react';

const EXPENSE_COLORS: Record<ExpenseCategory, string> = {
  'Entry Fees': '#EF4444',
  'Travel': '#F59E0B',
  'Fuel': '#10B981',
  'Tires': '#3B82F6',
  'Parts': '#6366F1',
  'Maintenance': '#8B5CF6',
  'Equipment': '#EC4899',
  'Safety Gear': '#F43F5E',
  'Marketing': '#14B8A6',
  'Insurance': '#06B6D4',
  'Other': '#6B7280'
};

interface BudgetProps {
  budget: Budget | null;
  setBudget: React.Dispatch<React.SetStateAction<Budget | null>>;
}

export default function Budget({ budget, setBudget }: BudgetProps) {
  const { programId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!programId) return;

    const fetchBudget = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const budgetRef = doc(db, 'programs', programId, 'budgets', currentYear.toString());
        const budgetSnap = await getDoc(budgetRef);

        if (budgetSnap.exists()) {
          setBudget({ id: budgetSnap.id, ...budgetSnap.data() } as Budget);
        }
      } catch (err) {
        console.error('Error fetching budget:', err);
        setError('Failed to load budget data');
        toast.error('Failed to load budget data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBudget();
  }, [programId]);

  const handleCreateBudget = async () => {
    if (!programId) return;

    try {
      setIsLoading(true);
      const currentYear = new Date().getFullYear();
      const budgetRef = doc(db, 'programs', programId, 'budgets', currentYear.toString());

      // Create a new budget for the current year
      const newBudget: Budget = {
        id: currentYear.toString(),
        seasonYear: currentYear,
        seasonExpenses: [],
        seasonIncome: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await setDoc(budgetRef, newBudget);
      setBudget(newBudget);
      toast.success('Created new budget for current year');
    } catch (err) {
      console.error('Error creating budget:', err);
      setError('Failed to create budget');
      toast.error('Failed to create budget');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateBudget = async (updatedBudget: Budget) => {
    if (!programId || !budget) return;

    try {
      const budgetRef = doc(db, 'programs', programId, 'budgets', budget.id);
      await setDoc(budgetRef, {
        ...updatedBudget,
        updatedAt: new Date().toISOString()
      });
      setBudget(updatedBudget);
      toast.success('Budget updated successfully');
    } catch (err) {
      console.error('Error updating budget:', err);
      toast.error('Failed to update budget');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  if (!budget) {
    return (
      <div className="flex flex-col items-center justify-center py-12 px-4">
        <div className="text-center max-w-md">
          <h1 className="text-2xl font-bold text-white mb-4">Set Up Your Budget</h1>
          <p className="text-gray-400 mb-8">
            Track your racing expenses, monitor spending, and plan your season's budget. 
            This is optional and can be set up later if you prefer.
          </p>
          <button
            onClick={handleCreateBudget}
            className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-5 h-5" />
            Create Budget
          </button>
          <p className="text-sm text-gray-500 mt-4">
            You can always access and manage your budget later from this page.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Budget</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MonthlyTrends data={calculateMonthlyTrends()} />
        <ExpenseBreakdown 
          data={calculateExpenseBreakdown()} 
          colors={EXPENSE_COLORS} 
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-dark-200 rounded-lg">
            <div className="border-b border-dark-50 p-4">
              <h2 className="text-lg font-semibold text-white">Season Budget</h2>
            </div>
            <SeasonBudget 
              budget={budget} 
              onUpdate={handleUpdateBudget} 
            />
          </div>
        </div>

        <div className="lg:sticky lg:top-6 h-fit">
          <BudgetSummaryPanel totals={calculateTotals()} />
        </div>
      </div>
    </div>
  );
}