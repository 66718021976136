const BASE_URL = 'https://vpic.nhtsa.dot.gov/api';

function sortByRelevance(items: { name: string }[], searchTerm: string): { name: string }[] {
  return [...items].sort((a, b) => {
    const aStartsWith = a.name.toLowerCase().startsWith(searchTerm.toLowerCase());
    const bStartsWith = b.name.toLowerCase().startsWith(searchTerm.toLowerCase());
    
    if (aStartsWith && !bStartsWith) return -1;
    if (!aStartsWith && bStartsWith) return 1;
    return a.name.localeCompare(b.name);
  });
}

export async function getAllMakes() {
  try {
    const response = await fetch(`${BASE_URL}/vehicles/GetAllMakes?format=json`);
    const data = await response.json();
    return data.Results.map((make: { Make_ID: number; Make_Name: string }) => ({
      id: make.Make_ID,
      name: make.Make_Name
    }));
  } catch (error) {
    console.error('Error fetching makes:', error);
    return [];
  }
}

export async function getModelsForMake(makeId: number) {
  try {
    const response = await fetch(`${BASE_URL}/vehicles/GetModelsForMakeId/${makeId}?format=json`);
    const data = await response.json();
    return data.Results.map((model: { Model_ID: number; Model_Name: string }) => ({
      id: model.Model_ID,
      name: model.Model_Name
    }));
  } catch (error) {
    console.error('Error fetching models:', error);
    return [];
  }
} 