import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { AlertTriangle, ExternalLink, Loader2 } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function Subscription() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleManageSubscription = async () => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createPortalSession = httpsCallable(functions, 'createPortalSession');
      const { data } = await createPortalSession();
      window.location.href = (data as { url: string }).url;
    } catch (error) {
      console.error('Error creating portal session:', error);
      // You might want to show a toast error here
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const { data } = await createCheckoutSession();
      window.location.href = (data as { url: string }).url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // You might want to show a toast error here
    } finally {
      setIsLoading(false);
    }
  };

  if (!user?.subscription) {
    return (
      <div className="space-y-6">
        <h1 className="text-2xl font-bold text-white">Subscription</h1>
        
        <div className="bg-dark-200 rounded-lg p-6">
          <div className="text-center">
            <AlertTriangle className="w-12 h-12 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-lg font-semibold text-white mb-2">
              No Active Subscription
            </h2>
            <p className="text-gray-400 mb-6">
              You currently don't have an active subscription.
            </p>
            <button
              onClick={handleSubscribe}
              disabled={isLoading}
              className="inline-flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 disabled:opacity-50"
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  Subscribe Now
                  <ExternalLink className="w-4 h-4" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Subscription</h1>

      {/* Current Plan */}
      <div className="bg-dark-200 rounded-lg p-6">
        <h2 className="text-lg font-semibold text-white mb-6">Current Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="text-sm text-gray-400">Status</p>
            <p className="text-lg font-medium text-white capitalize">
              {user.subscription.status}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Valid Until</p>
            <p className="text-lg font-medium text-white">
              {new Date(user.subscription.currentPeriodEnd).toLocaleDateString()}
            </p>
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <button
            onClick={handleManageSubscription}
            disabled={isLoading}
            className="inline-flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                Processing...
              </>
            ) : (
              <>
                Manage Subscription
                <ExternalLink className="w-4 h-4" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}